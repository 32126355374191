import Modal from "react-bootstrap/Modal";

import { Button, Card, Col, Row, Table } from "react-bootstrap";
import axios from "axios";
import { toast } from "react-toastify";

import React from "react";
import { formatLogInfo, generatePdfByJsPdf } from "helpers/utils";
import { useNavigate, useParams } from "react-router-dom";

// import RegisterEventWizard from './RegisterEventWizard/Wizard';
// import html2canvas from "html2canvas";
// import { jsPDF } from "jspdf";
import Loader from "components/common/Loader";

export default function EventsDetailModal({
  show,
  handleClose,
  payload = {},
  getAllData,
  setPayload,
  userData,
  hostname,
  jwtToken,
}) {
  // console.log(payload.event_ticket?.event_ticket_type !== "Free Ticket");
  //console.log(JSON.stringify(payload.registeredTickets));
  // console.log(payload);
  // console.log(typeof payload);

  const [loader, setLoader] = React.useState(false);
  const [ticketsCounter, setTicketsCounter] = React.useState([]);

  const printRef = React.useRef();

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    await generatePdfByJsPdf(element, "preview");
  };

  // const [goToInvoicePage, setGoToInvoicePage] = React.useState({
  //   price: 0.0,
  //   isShown: false,
  // });

  const navigate = useNavigate();

  const getTicketsState = () => {
    console.log("starting getTicketsState");

    setLoader(true);
    let arrToSend = [];
    if (payload?.event_ticket?.event_ticket_type === "Free Ticket") {
      arrToSend = [{ type: "Free", quantity: 0 }];
    } else {
      let tarr = [];

      payload?.event_ticket?.ticket_options?.forEach((v) => {
        tarr.push({
          type: payload.event_ticket.event_ticket_type,
          id: v._id,
          quantity: 0,
        });
      });
      arrToSend = tarr;
    }
    console.log(arrToSend);
    setTicketsCounter(arrToSend);
    console.log("ending getTicketsState");

    // setTimeout(() => {
    setLoader(false);
    // }, 400);

    return arrToSend;
  };

  React.useEffect(() => {
    getTicketsState();
  }, [show, payload]);

  console.log(ticketsCounter);

  const handleTicketsIncrement = (id) => {
    try {
      if (payload.event_ticket.event_ticket_type === "Free Ticket") {
        let arr = [
          { ...ticketsCounter[0], quantity: ticketsCounter[0].quantity + 1 },
        ];
        setTicketsCounter(arr);
      } else {
        let tsingle = ticketsCounter?.filter((v) => v.id === id)[0];
        let tindex = ticketsCounter?.findIndex((v) => v.id === id);
        console.log(tsingle);
        console.log(tindex);
        tsingle = { ...tsingle, quantity: tsingle.quantity + 1 };
        let tarr = [...ticketsCounter];
        tarr[tindex] = tsingle;
        console.log(tarr);
        setTicketsCounter([...tarr]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleTicketsDecrement = (id) => {
    if (payload.event_ticket.event_ticket_type === "Free Ticket") {
      if (ticketsCounter[0].quantity <= 0) {
        let arr = [{ ...ticketsCounter[0], quantity: 0 }];
        setTicketsCounter(arr);
        return;
      }
      let arr = [
        { ...ticketsCounter[0], quantity: ticketsCounter[0].quantity - 1 },
      ];
      setTicketsCounter(arr);
    } else {
      let tsingle = ticketsCounter?.filter((v) => v.id === id)[0];
      let tindex = ticketsCounter?.findIndex((v) => v.id === id);
      console.log(tsingle);
      console.log(tindex);

      if (tsingle.quantity <= 0) {
        tsingle = { ...tsingle, quantity: 0 };
        let tarr = [...ticketsCounter];
        tarr[tindex] = tsingle;
        console.log(tarr);

        setTicketsCounter([...tarr]);
        return;
      }

      tsingle = { ...tsingle, quantity: tsingle.quantity - 1 };
      let tarr = [...ticketsCounter];
      tarr[tindex] = tsingle;
      console.log(tarr);

      setTicketsCounter([...tarr]);
    }
  };

  const registerEventFinal = () => {
    let registerData = {
      unit_id: userData.UnitsOwner[0]._id, /////ABdullah
      user_id: userData.data,
      event_id: payload._id,
      ticket_info: [],
    };

    let tstate = [];

    if (payload.event_ticket.event_ticket_type === "Free Ticket") {
      let obj = {
        event_ticket: payload.event_ticket._id,
        selected_option_ids: null,
        quantity: ticketsCounter[0].quantity,
      };

      tstate.push(obj);
    } /// paid ticket
    else {
      payload.event_ticket.ticket_options?.forEach((ticket, i) => {
        let obj = {
          event_ticket: payload.event_ticket._id,
          selected_option_ids: ticketsCounter[i].id,
          quantity: ticketsCounter[i].quantity,
        };

        tstate.push(obj);
      });
    }

    console.log(tstate);

    registerData.ticket_info = [...tstate];
    console.log(registerData);
    registerData.show_remaining_tickets =
      payload.ticket_options?.show_remaining_tickets;

    // check available number of tickets

    if (payload.event_ticket.event_ticket_type === "Free Ticket") {
      if (tstate[0].quantity > payload.event_ticket.event_tickets_total) {
        toast.warning(
          "selected number of tickets exceeds over available tickets",
          { theme: "colored" }
        );
        return;
      }
    } else {
      let totalTs = 0;
      ticketsCounter?.forEach((v) => {
        totalTs += v.quantity;
      });
      console.log(totalTs);
      if (totalTs > payload.event_ticket.event_tickets_total) {
        toast.warning(
          "selected number of tickets exceeds over available tickets",
          { theme: "colored" }
        );
        return;
      }
    }

    console.log(registerData);
    // console.log(userData);

    //return;

    axios
      .post(
        `${hostname}/api/property/RegisterTicket`,
        {
          ...registerData,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        if (payload.event_ticket.event_ticket_type === "Free Ticket") {
          if (payload.isRegistered) {
            toast.success(" success!", { theme: "colored" });
          } else {
            toast.success("registration success!", { theme: "colored" });
          }
        } else {
          // setGoToInvoicePage({ isShown: true });
          toast.success(
            "Your event registration is complete. Please proceed to complete your payment on the invoice page.",
            {
              theme: "colored",
              autoClose: false,
            }
          );
          setTimeout(() => {
            navigate(
              `/owner-portal/my-invoices/${userData?.UnitsOwner[0]?._id}/${userData?.UnitsOwner[0]?.unit_number}/${res.data.Invoice_Id}`
            );
          }, 400);
        }
        // setPayload({ ...payload, isRegistered: true });
        getAllData();
        handleClose();
        // setTicketsCounter([]);
      })
      .catch((err) => {
        console.log(err);
        toast.error("something went wrong!", { theme: "colored" });
      });
  };

  const getStatusOfRegisterBtn = () => {
    if (payload.event_ticket.event_ticket_type === "Free Ticket") {
      if (ticketsCounter[0]?.quantity === 0) return true;
      else return false;
    }
    if (payload.event_ticket.event_ticket_type !== "Free Ticket") {
      if (Array.isArray(ticketsCounter)) {
        // Iterate over each object in the array
        for (let v of ticketsCounter) {
          // Check if the object has a 'quantity' key and its value is greater than 0
          if (v.quantity > 0) {
            return false; // Return false if any quantity is greater than 0
          }
        }
      }
      // If no quantity is greater than 0, return true
      return true;
    }
  };

  if (loader)
    return (
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"lg"}
        centered
      >
        <Modal.Header closeButton>
          <div
            className="d-flex justify-content-between"
            style={{ width: "90%" }}
          >
            <Modal.Title>{payload.loadType}</Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <Loader noHeight />
        </Modal.Body>
      </Modal>
    );
  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size={"lg"}
        centered
      >
        <Modal.Header closeButton>
          <div
            className="d-flex justify-content-between"
            style={{ width: "90%" }}
          >
            <Modal.Title>{payload.loadType}</Modal.Title>
            {payload.loadType === "View Event Tickets" && (
              <Button variant="falcon-primary" onClick={handleDownloadPdf}>
                Print
              </Button>
            )}
          </div>
        </Modal.Header>
        <Modal.Body ref={printRef}>
          {(payload.loadType === "Register Event" ||
            payload.loadType === "Buy More Tickets") && (
            <>
              {payload.event_ticket.event_tickets_total < 1 ? (
                <div
                  style={{
                    minHeight: "400px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3>All Tickets Sold!</h3>
                </div>
              ) : (
                <>
                  {payload.event_ticket.event_ticket_type === "Free Ticket" && (
                    <div className="d-flex justify-content-center align-items-center">
                      <div
                        onClick={handleTicketsDecrement}
                        className=""
                        style={{
                          fontSize: "40px",
                          marginRight: "50px",
                          border: "1px solid silver",
                          borderRadius: "20%",
                          padding: "0px 20px",
                          cursor: "pointer",
                        }}
                      >
                        -
                      </div>
                      <div className="" style={{ fontSize: "80px" }}>
                        {ticketsCounter[0]?.quantity}
                      </div>
                      <div
                        onClick={handleTicketsIncrement}
                        className=""
                        style={{
                          fontSize: "40px",
                          marginLeft: "50px",
                          border: "1px solid silver",
                          borderRadius: "20%",
                          padding: "0px 20px",
                          cursor: "pointer",
                        }}
                      >
                        +
                      </div>
                    </div>
                  )}
                  {payload.event_ticket.event_ticket_type !== "Free Ticket" && (
                    <>
                      <Table
                        responsive
                        className="border border-1 text-center"
                        bordered
                      >
                        <thead>
                          <tr className="fw-bold">
                            <td>#</td>
                            <td>Ticket</td>
                            <td>Price</td>
                            <td>Count</td>
                          </tr>
                        </thead>
                        <tbody>
                          {payload.event_ticket.ticket_options?.map((v, i) => {
                            if (v.enabled)
                              return (
                                <tr key={v._id}>
                                  <td>{i + 1}</td>
                                  <td>{v.name}</td>
                                  <td>${v.price}</td>
                                  <td>
                                    <div className="d-flex justify-content-center align-items-center">
                                      <div
                                        onClick={() =>
                                          handleTicketsDecrement(v._id)
                                        }
                                        className=""
                                        style={{
                                          // fontSize: "10px",
                                          marginRight: "20px",
                                          border: "1px solid silver",
                                          // borderRadius: "10%",
                                          padding: "0px 10px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        -
                                      </div>
                                      <div
                                        className=""
                                        style={{ fontSize: "20px" }}
                                      >
                                        {ticketsCounter[i]?.quantity}
                                      </div>
                                      <div
                                        onClick={() =>
                                          handleTicketsIncrement(v._id)
                                        }
                                        className=""
                                        style={{
                                          // fontSize: "10px",
                                          marginLeft: "20px",
                                          border: "1px solid silver",
                                          borderRadius: "10%",
                                          padding: "0px 10px",
                                          cursor: "pointer",
                                        }}
                                      >
                                        +
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            return <span />;
                          })}
                        </tbody>
                      </Table>
                    </>
                  )}

                  {payload.event_ticket?.show_remaining_tickets && (
                    <p>
                      Remaining Tickets{" : "}
                      {payload.event_ticket?.event_tickets_total -
                        payload.event_ticket.event_sold_tickets_total}
                    </p>
                  )}
                  <div className="text-end">
                    <Button
                      variant="primary"
                      disabled={getStatusOfRegisterBtn()}
                      onClick={registerEventFinal}
                    >
                      Complete Registration
                    </Button>
                  </div>
                </>
              )}
            </>
            // <RegisterEventWizard />
          )}
          {payload.loadType === "View Event Tickets" && (
            <div className="">
              <Row className="d-flex justify-content-between">
                {payload.event_ticket.event_ticket_type === "Free Ticket" && (
                  <Col md={6}>
                    <Card className="shadow-none">
                      <Card.Body
                        className="shadow-none"
                        style={{ minHeight: "300px" }}
                      >
                        {/* <div className="text-center mb-3">
                          <Button
                            size={"sm"}
                            variant="falcon-primary"
                            className="float-end"
                            onClick={handleDownloadPdf}
                          >
                            Print
                          </Button>
                        </div> */}

                        <div className="mb-3">
                          <h6 className="text-secondary mb-1 ">Name</h6>
                          <h5 className="fs-bold">
                            {payload.userData.firstname}{" "}
                            {payload.userData.lastname}
                          </h5>
                        </div>
                        <div className="mb-3">
                          <h6 className="text-secondary mb-1">Event</h6>
                          <h5>{payload.event_name}</h5>
                        </div>
                        <div className="mb-3 row">
                          <Col md={6}>
                            <h6 className="text-secondary mb-1">Ticket</h6>
                            <h5>Free</h5>
                          </Col>
                          <Col md={6}>
                            <h6 className="text-secondary mb-1">Price</h6>
                            <h5>$0</h5>
                          </Col>
                        </div>

                        <Row>
                          <Col md={6}>
                            {" "}
                            <div className="mb-3">
                              <h6 className="text-secondary mb-1">Date</h6>
                              <p>{payload.duration}</p>
                            </div>
                          </Col>
                          <Col md={6}>
                            {" "}
                            <div className="mb-3">
                              <h6 className="text-secondary mb-1">Quantity</h6>
                              <h5 className="fw-bold">
                                {
                                  // payload?.event_ticket
                                  //   ?.event_sold_tickets_total

                                  payload?.registeredTickets[0]?.tickets[0]
                                    ?.quantity
                                }
                              </h5>
                            </div>
                          </Col>
                        </Row>
                        <div className="mb-3">
                          <h6 className="text-secondary mb-1">Venue</h6>
                          <h5 className="fw-bold">{payload.venue}</h5>
                        </div>
                        <Row>
                          {payload?.event_days?.map((v) => (
                            <Col md={6} key={v._id}>
                              Day : {v.day}
                              <br />
                              Start : {`${v.start_time} ${v.start_timezone} `}
                              <br />
                              End : {`${v.end_time} ${v.end_timezone} `}
                              <hr />
                            </Col>
                          ))}
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                )}
                {payload.event_ticket.event_ticket_type !== "Free Ticket" &&
                  payload.registeredTickets?.map((item, i) => {
                    return item.tickets?.map((v) => {
                      if (v.quantity > 0)
                        return (
                          <Col
                            md={6}
                            key={v._id}
                            className={`mb-3 ${i % 2 == 0 && "pe-lg-0"}`}
                          >
                            <TicketCard
                              payload={payload}
                              v={v}
                              i={i}
                              printRef={printRef}
                              handleDownloadPdf={handleDownloadPdf}
                            />
                          </Col>
                        );
                      return <span />;
                    });
                  })}
              </Row>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}

function TicketCard({ payload, v, printRef, handleDownloadPdf }) {
  return (
    // <Card ref={printRef}>
    <Card className="shadow-none">
      <Card.Body style={{ minHeight: "300px" }}>
        <div className="text-center mb-3">
          {/* <span>
            Ticket {i + 1} of {payload?.event_ticket?.event_sold_tickets_total}
          </span> */}
          {/* <Button
            size={"sm"}
            variant="falcon-primary"
            className="float-end"
            onClick={handleDownloadPdf}
          >
            Print
          </Button> */}
        </div>

        <div className="mb-3">
          <h6 className="text-secondary mb-1 ">Name</h6>
          <h5 className="fs-bold">
            {payload.userData.firstname} {payload.userData.lastname}
          </h5>
        </div>
        <div className="mb-3">
          <h6 className="text-secondary mb-1">Event</h6>
          <h5>{payload.event_name}</h5>
        </div>
        <div className="mb-3 row">
          <Col md={6}>
            <h6 className="text-secondary mb-1">Ticket</h6>
            <h5>{v?.selected_option_ids?.name}</h5>
          </Col>
          <Col md={6}>
            <h6 className="text-secondary mb-1">Price</h6>
            <h5>${v?.selected_option_ids?.price}</h5>
          </Col>
        </div>

        <Row>
          <Col md={6}>
            {" "}
            <div className="mb-3">
              <h6 className="text-secondary mb-1">Date</h6>
              <p>
                {formatLogInfo(payload.event_start_date, true)?.split(",")[0]}
                {" - "}
                {formatLogInfo(payload.event_end_date, true)?.split(",")[0]}
              </p>
            </div>
          </Col>
          <Col md={6}>
            {" "}
            <div className="mb-3">
              <h6 className="text-secondary mb-1">Quantity</h6>
              <h5 className="fw-bold">{v?.quantity}</h5>
            </div>
          </Col>
        </Row>
        <div className="mb-3">
          <h6 className="text-secondary mb-1">Venue</h6>
          <h5 className="fw-bold">{payload.venue}</h5>
        </div>
        <Row>
          {payload?.event_days?.map((v) => (
            <Col md={6} key={v._id}>
              Day : {v.day}
              <br />
              Start : {`${v.start_time} ${v.start_timezone} `}
              <br />
              End : {`${v.end_time} ${v.end_timezone} `}
              <hr />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
}
