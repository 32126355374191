import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { toast, ToastContainer } from "react-toastify";
import { CloseButton } from "../components/common/Toast";

import AuthSimpleLayout from "./AuthSimpleLayout";
import MainLayout from "./MainLayout";

import Settings from "../components/pages/user/settings/Settings";

import Error404 from "../components/errors/Error404";
import Error500 from "../components/errors/Error500";
// import Error401 from '../components/errors/Error401';
import Error401New from "../components/errors/Error401New";

import SimpleLogin from "../components/authentication/simple/Login";
import SimpleLogout from "../components/authentication/simple/Logout";
import SimpleForgetPassword from "../components/authentication/simple/ForgetPassword";
import SimplePasswordReset from "../components/authentication/simple/PasswordReset";

// import PrivateRoutes from 'Private/PrivateRoutes';
import Dashboard from "../components/dashboards/default";

import UserRegistration from "../components/authentication/User/UserRegistration";
import CompanyRegistration from "../components/authentication/Company/CompanyRegistration";
import CompanyProfile from "../components/pages/Management/CompanyProfile/CompanyProfile";

import ManageUsers from "../components/pages/Management/Users";
import ManageUsersDetail from "../components/pages/Management/userDetails/UserDetailsComponent";

import ManageGroups from "../components/pages/Management/Groups";
import ManageGroupsDetail from "../components/pages/Management/GroupDetailsComponent";
import Billing from "../components/app/e-commerce/billing/Billing";
// import Paymenttest from "../components/app/e-commerce/billing/Paymenttest";
import PaymentProessor from "../components/pages/BuildingManagement/BuildingInfoFolder/paymentProcessor/PaymentProessor";

import ManageBuildings from "../components/pages/BuildingManagement/Property";

import {
  Profile as PropertyProfile,
  Financials as PropertyFinancials,
  Budgets as PropertyBudget,
  UnitInfo as PropertyUnits,
  PaymentsReceived as PropertyPaymentsReceived,
} from "../components/pages/BuildingManagement/BuildingInfoFolder";

// unit data
import {
  UnitProfile,
  UnitAttachedUnits,
  UnitOwners,
  UnitVehicleInfo,
  UnitPetInfo,
  UnitViolationsTrackingInfo,
  UnitInvoices,
  UnitHistory,
  UnitPayments,
  UnitBookedAmenities,
  UnitMaintenanceReqs,
  UnitVisitorManagement,
  UnitVisitorParking,
} from "../components/pages/BuildingManagement/BuildingInfoFolder/UnitInfo/unitDetails";

import {
  PropertyPortalAmenities,
  PropertyPortalAmenityBookings,
  PropertyPortalAnnouncements,
  PropertyPortalBulletin,
  //PropertyPortalComplaints,
  PropertyPortalEventsCalender,
  PropertyPortalDocumentDrive,
  PropertyPortalDocumentDriveTrash,
  PropertyPortalMailOuts,
  PropertyPortalOnlinePayments,
  PropertyPortalOwnerDirectory,
  PropertyPortalViolationTracking,
  PropertyPortalVisitorManagement,
  PropertyPortalVisitorParkingManagement,
  // PropertyPortalAccountIntegration,
  //PropertyPortalMaintainceTracking,
  PropertyPortalPortfolioManagement,
  PropertyPortalServiceRequests,
  PropertyPortalAccountsIntegrations,
  PropertyPortalEventsCalenderDetailPage,
} from "../components/pages/Features";

// unitowner/tennet
import {
  OwnerPortalUnitDetail,
  OwnerPortalUsersDetail,
  OwnerPortalVehicleDetail,
  OwnerPortalPetDetail,
  OwnerPortalMyBookingsDetail,
  OwnerPortalPaymentsHistoryDetail,
  OwnerPortalAllUnits,
  OwnerPortalMyInvoicesDetail,
} from "../components/pages/UnitOnwer&Tennent";

import {
  OwnerPortalAmenityBookings,
  OwnerPortalAnnouncements,
  OwnerPortalBulletinBoard,
  // OwnerPortalComplaints,
  OwnerPortalEventsCalender,
  OwnerPortalFileLibrary,
  // OwnerPortalMailOuts,
  // OwnerPortalOnlinePayments,
  OwnerPortalOwnerDirectory,
  OwnerPortalViolationTracking,
  OwnerPortalVisitorManagement,
  OwnerPortalVisitorParkingManagement,
  // OwnerPortalAccountIntegration,
  // OwnerPortalMaintainceTracking,
  // OwnerPortalPortfolioManagement,
  OwnerPortalInbox,
  OwnerPortalServiceRequests,
  OwnerPortalIntegations,
  OwnerPortalInboxDetail,
} from "../components/pages/UnitOnwer&Tennent/Features";

import ErrorLayout from "./ErrorLayout";
import CreateEventPM from "../components/pages/Features/EventsCalender/CreateEventPM";
import EventDetailPM from "../components/pages/Features/EventsCalender/EventDetailPM";

import NotificationCenter from "../components/pages/sharedUI/NotificationsCenter/NotificationCenter";
import ErrorNotInvited from "../components/errors/ErrorNotInvited";

import Cookies from "universal-cookie";
import jwtDecode from "jwt-decode";


const wizardAuthRoutes = [
  {
    path: "/register",
    element: <UserRegistration />,
  },
  {
    path: "/register/:EParameter",
    element: <UserRegistration />,
  },
  {
    path: "/register/:EParameter/:portal",
    element: <UserRegistration />,
  },
  {
    path: "/company/register",
    element: <CompanyRegistration />,
  },
];

const authRoutes = [
  {
    path: "/login",
    element: <SimpleLogin />,
  },
  {
    path: "/logout",
    element: <SimpleLogout />,
  },
  {
    path: "/forgot-password",
    element: <SimpleForgetPassword />,
  },
  {
    path: "/reset-password/:resetToken",
    element: <SimplePasswordReset />,
  },
  {
    path: "/reset-password/:resetToken/:user_from_reset_ps",
    element: <SimplePasswordReset />,
  },
  {
    path: "/property/login",
    element: <SimpleLogin />,
  },
  {
    path: "/user/notifications/:user_role",
    element: <NotificationCenter />,
  },
  {
    path: "/property/logout",
    element: <SimpleLogout />,
  },
];

const commonRoutes = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/dashboard/:unit_id/:unit_number",
    element: <Dashboard />,
  },
  {
    path: "/user/settings",
    element: <Settings />,
  },
];

const reg_companyRoutes = [
  {
    path: "admin/management/company/:company_id/profile",
    element: <CompanyProfile />,
  },
  {
    path: "admin/management/users",
    element: <ManageUsers />,
  },
  {
    path: "admin/management/users/:user_id",
    element: <ManageUsersDetail />,
  },
  {
    path: "admin/management/groups",
    element: <ManageGroups />,
  },
  {
    path: "admin/management/billing",
    element: <Billing />,
  },
  {
    path: "admin/management/groups/:group_name",
    element: <ManageGroupsDetail />,
  },
  {
    path: "/property/management/buildings",
    element: <ManageBuildings />,
  },

  {
    path: "/property/management/buildings/:property_id/:property_name/profile",
    element: <PropertyProfile />,
  },
  {
    path: "/property/management/buildings/:property_id/:property_name/financials",
    element: <PropertyFinancials />,
  },
  {
    path: "/property/management/buildings/:property_id/:property_name/budget",
    element: <PropertyBudget />,
  },
  {
    path: "/property/management/buildings/:property_id/:property_name/invoices",
    element: <OwnerPortalMyInvoicesDetail />,
  },
  {
    path: "/property/management/buildings/:property_id/:property_name/payment-processor",
    element: <PaymentProessor />,
  },
  {
    path: "/property/management/buildings/:property_id/:property_name/payments",
    element: <PropertyPaymentsReceived />,
  },
  {
    path: "/property/management/buildings/:property_id/:property_name/units",
    element: <PropertyUnits />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/details",
    element: <UnitProfile />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/attached-units",
    element: <UnitAttachedUnits />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/owners",
    element: <UnitOwners />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/vehicle-info",
    element: <UnitVehicleInfo />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/pet-info",
    element: <UnitPetInfo />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/violations-tracking",
    element: <UnitViolationsTrackingInfo />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/unit-invoices",
    element: <UnitInvoices />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/unit-payments",
    element: <UnitPayments />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/unit-booked-amenities",
    element: <UnitBookedAmenities />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/unit-maintenance-requests",
    element: <UnitMaintenanceReqs />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/unit-visitor-parking",
    element: <UnitVisitorParking />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/unit-visitor-management",
    element: <UnitVisitorManagement />,
  },
  {
    path: "/:property_id/:property_name/:unit_number/:unit_id/history",
    element: <UnitHistory />,
  },
];

const reg_ownerRoutes = [
  {
    path: "/owner-portal/unit-details/:unit_id/:unit_number",
    element: <OwnerPortalUnitDetail />,
  },
  {
    path: "/owner-portal/residents-detail/:unit_id/:unit_number",
    element: <OwnerPortalUsersDetail />,
  },
  {
    path: "/owner-portal/all-units/:unit_id/:unit_number",
    element: <OwnerPortalAllUnits />,
  },
  {
    path: "/owner-portal/vehicle-details/:unit_id/:unit_number",
    element: <OwnerPortalVehicleDetail />,
  },
  {
    path: "/owner-portal/pet-details/:unit_id/:unit_number",
    element: <OwnerPortalPetDetail />,
  },
  {
    path: "/owner-portal/my-bookings",
    element: <OwnerPortalMyBookingsDetail />,
  },
  {
    path: "/owner-portal/my-invoices/:unit_id/:unit_number",
    element: <OwnerPortalMyInvoicesDetail />,
  },
  {
    path: "/owner-portal/my-invoices/:unit_id/:unit_number/:invoiceDetailId",
    element: <OwnerPortalMyInvoicesDetail />,
  },
  {
    path: "/owner-portal/payments-history",
    element: <OwnerPortalPaymentsHistoryDetail />,
  },
];

const errorRoutes = [
  {
    path: "/errors/404",
    element: <Error404 />,
  },
  {
    path: "/errors/500",
    element: <Error500 />,
  },
  {
    path: "errors/401/not-authorized",
    element: <Error401New />,
  },
  {
    path: "errors/401/not-invited/:user_exit_role",
    element: <ErrorNotInvited />,
  },
];

const allFeaturesRoutes = [
  {
    path: "/owner-directory",
    element: <PropertyPortalOwnerDirectory />,
  },
  {
    path: "/property/features/:property_id/:property_name/amenities",
    element: <PropertyPortalAmenities />,
  },
  {
    path: "/property/features/:property_id/:property_name/amenity-bookings",
    element: <PropertyPortalAmenityBookings />,
  },
  {
    path: "/property/features/:property_id/:property_name/annuncements",
    element: <PropertyPortalAnnouncements />,
  },
  {
    path: "/property/features/:property_id/:property_name/bulletins",
    element: <PropertyPortalBulletin />,
  },
  // {
  //   path: '/property/features/:property_id/:property_name/complaints',
  //   element: <Complaints />
  // },
  {
    path: "/property/features/:property_id/:property_name/service-requests",
    element: <PropertyPortalServiceRequests />,
  },
  {
    path: "/property/features/:property_id/:property_name/events-calender",
    element: <PropertyPortalEventsCalender />,
  },
  {
    path: "/property/features/:property_id/:property_name/events-calender/event-details/:event_id",
    element: <PropertyPortalEventsCalenderDetailPage />,
  },
  {
    path: "/property/features/:property_id/:property_name/events-add",
    element: <CreateEventPM />,
  },
  {
    path: "/property/features/:property_id/:property_name/events-details",
    element: <EventDetailPM />,
  },
  {
    path: "/property/features/:property_id/:property_name/document-drive",
    element: <PropertyPortalDocumentDrive />,
  },
  {
    path: "/property/features/:property_id/:property_name/document-drive/trash",
    element: <PropertyPortalDocumentDriveTrash />,
  },
  {
    path: "/property/features/:property_id/:property_name/mail-outs",
    element: <PropertyPortalMailOuts />,
  },
  {
    path: "/property/features/:property_id/:property_name/online-payments",
    element: <PropertyPortalOnlinePayments />,
  },
  {
    path: "/property/features/:property_id/:property_name/owner-directory",
    element: <PropertyPortalOwnerDirectory />,
  },
  {
    path: "/property/features/:property_id/:property_name/violation-tracking",
    element: <PropertyPortalViolationTracking />,
  },
  {
    path: "/property/features/:property_id/:property_name/visitor-management",
    element: <PropertyPortalVisitorManagement />,
  },
  {
    path: "/property/features/:property_id/:property_name/visitor-parking-management",
    element: <PropertyPortalVisitorParkingManagement />,
  },
  {
    path: "/property/features/:property_id/:property_name/accounts-integrations",
    element: <PropertyPortalAccountsIntegrations />,
  },
  // {
  //   path: '/property/features/:property_id/:property_name/maintaince-tracking',
  //   element: <MaintainceTracking />
  // },
  {
    path: "/property/features/:property_id/:property_name/portfolio-management",
    element: <PropertyPortalPortfolioManagement />,
  },
];

const ownerPortalFeaturesRoutes = [
  {
    path: "/owner-portal/inbox",
    element: <OwnerPortalInbox />,
  },
  {
    path: "/owner-portal/inbox/:mail_id",
    element: <OwnerPortalInboxDetail />,
  },
  {
    path: "/owner-portal/amenities/:unit_id/:unit_number",
    element: <OwnerPortalAmenityBookings />,
  },
  {
    path: "/owner-portal/annuncements",
    element: <OwnerPortalAnnouncements />,
  },
  {
    path: "/owner-portal/bulletin-board",
    element: <OwnerPortalBulletinBoard />,
  },
  {
    path: "/owner-portal/bulletin-board/:post_id",
    element: <OwnerPortalBulletinBoard />,
  },
  {
    path: "/owner-portal/service-requests/:unit_id/:unit_number",
    element: <OwnerPortalServiceRequests />,
  },
  // {
  //   path: '/owner-portal/complaints',
  //   element: <OwnerPortalComplaints />
  // },
  {
    path: "/owner-portal/events-calender",
    element: <OwnerPortalEventsCalender />,
  },
  {
    path: "/owner-portal/events-calender/event-details/:event_id",
    element: <PropertyPortalEventsCalenderDetailPage />,
  },
  {
    path: "/owner-portal/file-library",
    element: <OwnerPortalFileLibrary />,
  },
  // {
  //   path: '/owner-portal/mail-outs',
  //   element: <OwnerPortalMailOuts />
  // },
  // {
  //   path: '/owner-portal/online-payments',
  //   element: <OwnerPortalOnlinePayments />
  // },
  {
    path: "/owner-portal/owner-directory",
    element: <OwnerPortalOwnerDirectory />,
  },
  {
    path: "/owner-portal/violation-tracking",
    element: <OwnerPortalViolationTracking />,
  },
  {
    path: "/owner-portal/visitors",
    element: <OwnerPortalVisitorManagement />,
  },
  {
    path: "/owner-portal/visitor-parking-management",
    element: <OwnerPortalVisitorParkingManagement />,
  },
  {
    path: "/owner-portal/accounts-integrations",
    element: <OwnerPortalIntegations />,
  },
  // {
  //   path: '/owner-portal/maintaince-tracking',
  //   element: <OwnerPortalMaintainceTracking />
  // },
  // {
  //   path: '/owner-portal/portfolio-management',
  //   element: <OwnerPortalPortfolioManagement />
  // }
];

const Layout = () => {
  const cookies = new Cookies();
  let jwtToken = cookies.get("_expire_t");
  // const rlink = cookies.get("_expire_l");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  console.log(userData);
  

  // let { userType } = React.useContext(UserContext);

  // const getExpireRedirect = () => {
  //   let path = "/login";
  //   if (userType === "prop-mgmt") path = "/login";

  //   if (userType === "unit-user") path = "/property/login";

  //   return path;
  // };

  // console.log(rme);

  // This is your payment form component

  // const stripePromise = loadStripe("your_stripe_publishable_key");

  return (
    <>
      <Routes>
        {/* <Route path="date" element={<DatePickerExample />}/> */}

        {/* -------------------------------- auth ---------------------------- */}
        <Route element={<AuthSimpleLayout />}>
          {authRoutes.map((v, i) => (
            <Route key={i + v.path} path={v.path} element={v.element} />
          ))}
        </Route>
        {/* -------------------------------- wizard ---------------------------- */}
        {wizardAuthRoutes.map((v, i) => (
          <Route key={i + v.path} path={v.path} element={v.element} />
        ))}

        {/* -------------------------------- main ---------------------------- */}

        {/* <Route element={<PrivateRoutes />}> */}

        <Route
          path="/"
          element={
            userData ? (
              <Navigate to="/dashboard" />
            ) : (
              <Navigate to={'/login'} />
            )
          }
        />

        {userData !== undefined &&
          userData !== "undefined" &&
          userData !== "" && (
            <Route element={<MainLayout />}>
              {commonRoutes.map((v, i) => (
                <Route
                  key={i + v.path}
                  path={v.path}
                  element={v.element}
                  // element={newHOC(v.element, { ...props })}
                />
              ))}
              {reg_companyRoutes.map((v, i) => (
                <Route key={i + v.path} path={v.path} element={v.element} />
              ))}
              {reg_ownerRoutes.map((v, i) => (
                <Route key={i + v.path} path={v.path} element={v.element} />
              ))}
              {allFeaturesRoutes.map((v, i) => (
                <Route key={i + v.path} path={v.path} element={v.element} />
              ))}
              {ownerPortalFeaturesRoutes.map((v, i) => (
                <Route key={i + v.path} path={v.path} element={v.element} />
              ))}
            </Route>
          )}

        {/* {(userData === undefined || userData === "undefined" || userData === '') && (
          <Route path="/" element={<Navigate to={rlink} />} />
        )} */}
        {/* </Route> */}
        {/* -------------------------------- error ---------------------------- */}
        <Route element={<ErrorLayout />}>
          {errorRoutes.map((v, i) => (
            <Route key={i + v.path} path={v.path} element={v.element} />
          ))}
          <Route path="*" element={<Navigate to="/errors/404" />} />
        </Route>
      </Routes>

      {/* <SettingsToggle />
        <SettingsPanel />
      */}
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
        theme="colored"
      />
    </>
  );
};

export default Layout;
