import { CloudUpload } from "@mui/icons-material";
import axios from "axios";
import { getPageCount } from "helpers/utils-pdf";
import React from "react";
//import { Image } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
// import pdftohtml from 'pdf2html';
export default function CompanyProfileModal({
  show,
  handleClose,
  payload,
  //setPayload,
  company_id,
  getLetterHeadData,
  companyLetterHead,
  //viewLetterHead,
  setCompanyLetterHead,
  hostname,
  jwtToken,
}) {
  // console.log(payload);
  // console.log(companyLetterHead);

  const [letterHeadFile, setLetterHeadFile] = React.useState([]);

  const handleFileChange = async (e) => {
    if (e?.target?.files[0]?.type !== "application/pdf") {
      toast.error("Unsupported File Format. Use Pdf Format.", {
        theme: "colored",
      });
      e.target.value = null;

      return;
    }

    if (e?.target?.files[0]?.size > "1e+6") {
      toast.error("Your file size is too large. Max file limit is 1MB.", {
        theme: "colored",
      });
      return;
    }
    if (e?.target?.files[0]) {
      let pages = await getPageCount(e?.target?.files[0]);
      console.log(pages);

      if (pages > 1) {
        toast.error(
          "Your letterhead file has multiple pages. Please upload a single-page letterhead file",
          {
            theme: "colored",
          }
        );
        return;
      }
    }

    setLetterHeadFile(e.target.files);
  };

  const handleDeleteLetterHead = () => {
    axios
      .post(
        `${hostname}/api/property/DeletePdfCompany`,
        {
          c_id: company_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        toast.info("Letterhead deleted.", { theme: "colored" });
        getLetterHeadData();
        setCompanyLetterHead("");
        handleFinalClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleUploadLetterHead = async () => {
    if (letterHeadFile[0]?.type !== "application/pdf") {
      toast.error("Unsupported File Format. Use Pdf Format.", {
        theme: "colored",
      });
      return;
    }

    if (letterHeadFile[0]?.size > "2e+6") {
      toast.error("Your file size is too large. Max file limit is 2MB.", {
        theme: "colored",
      });
      return;
    }
    if (letterHeadFile[0]) {
      let pages = await getPageCount(letterHeadFile[0]);

      if (pages > 1) {
        toast.error(
          "Your letterhead file has multiple pages. Please upload a single-page letterhead file",
          {
            theme: "colored",
          }
        );
        return;
      }
    }

    axios
      .post(
        `${hostname}/api/property/AddLetterHeadPdfCompany`,
        { req_pdf: letterHeadFile[0], c_id: company_id },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res);
        toast.info("Letterhead uploaded.", { theme: "colored" });
        getLetterHeadData();
        handleFinalClose();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleFinalClose = () => {
    setLetterHeadFile([]);
    handleClose();
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleFinalClose}
        backdrop="static"
        keyboard={false}
        size={payload.loadType === "Delete LetterHead" ? "md" : "xl"}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{payload.loadType}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {payload.loadType === "Upload LetterHead" && (
            <div style={{ minHeight: "150vh" }}>
              Upload letterhead in PDF format.
              <div className="my-2">
                {letterHeadFile.length > 0 && (
                  <div className="text-end">
                    <Button
                      variant="primary"
                      size="sm"
                      onClick={handleUploadLetterHead}
                      className="me-3"
                    >
                      Done
                    </Button>
                    <Button
                      variant="falcon-danger"
                      size="sm"
                      onClick={() => setLetterHeadFile([])}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {letterHeadFile.length > 0 && (
                  <div
                    className="mt-3"
                    style={{
                      borderRadius: "3px",
                      // border: "2px solid silver",
                      boxShadow: "0px 0px 3px ",
                      overflow: "hidden",
                      height: "180vh",
                      width: "100%",
                    }}
                  >
                    <object
                      aria-label="compnany letterhead"
                      style={{ width: "100%", height: "150vh" }}
                      // src={
                      //   from === "Edit Event Page"
                      //     ? values.event_cover_image instanceof File
                      //       ? URL.createObjectURL(values.event_cover_image)
                      //       : values.event_cover_image
                      //     : URL.createObjectURL(values.event_cover_image)
                      // }
                      data={URL.createObjectURL(letterHeadFile[0])}
                    >loading taking more than usual time...</object>
                  </div>
                )}
                {letterHeadFile.length < 1 && (
                  <label
                    htmlFor="event_cover_image"
                    className="d-flex justify-content-center align-items-center fs-2 cursor-pointer"
                    style={{
                      display: "block",
                      height: "90vh",
                      borderRadius: "10px",
                      // backgroundColor:'silver',
                      border: "3px dashed #dee2ef",
                      width: "100%",
                      minHeight: "100vh",
                    }}
                  >
                    <input
                      onChange={
                        handleFileChange
                        // console.log(e.target.files);
                        // setLetterHeadFile(e.target.files);
                        // setFieldValue("event_cover_image", e.target.files[0]);
                      }
                      // onBlur={handleBlur}
                      // value={values.event_cover_image}
                      id="event_cover_image"
                      name="event_cover_image"
                      type="file"
                      // accept="image/*"
                      hidden
                      className="form-control"
                    />
                    <div>
                      <CloudUpload fontSize="large" /> Click to upload
                    </div>
                  </label>
                )}
              </div>
              <br />
            </div>
          )}
          {payload.loadType === "View/Change LetterHead" && (
            <div style={{ minHeight: "150vh" }}>
              <div className="my-2">
                <div className="d-flex justify-content-end ">
                  {letterHeadFile.length > 0 ? (
                    payload.loadType === "View/Change LetterHead" && (
                      <>
                        <label
                          className="btn btn-primary btn-sm me-3"
                          onClick={() => handleUploadLetterHead(true)}
                        >
                          Done
                        </label>
                        <label
                          className="btn btn-danger btn-sm me-3"
                          onClick={() => setLetterHeadFile([])}
                        >
                          Cancel
                        </label>
                      </>
                    )
                  ) : (
                    <label className="btn btn-success btn-sm">
                      <input type="file" hidden onChange={handleFileChange} />
                      Change
                    </label>
                  )}
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center">
                <div
                  className="mt-3"
                  style={{
                    borderRadius: "3px",
                    // border: "2px solid silver",
                    boxShadow: "0px 0px 3px ",
                    overflow: "hidden",
                    height: "180vh",
                    width: "100%",
                  }}
                >
                  {companyLetterHead !== "" &&
                    letterHeadFile.length < 1 &&
                    payload.loadType === "View/Change LetterHead" && (
                      <object
                        aria-label="compnany letterhead"
                        style={{
                          width: "100%",
                          height: "180vh",
                          backgroundColor: "red",
                        }}
                        data={companyLetterHead}
                      >loading taking more than usual time...</object>
                    )}
                  {companyLetterHead !== "" &&
                    letterHeadFile.length > 0 &&
                    payload.loadType === "View/Change LetterHead" && (
                      <object
                        aria-label="compnany letterhead"
                        style={{
                          width: "100%",
                          height: "180vh",
                          backgroundColor: "red",
                        }}
                        data={URL.createObjectURL(letterHeadFile[0])}
                      >loading taking more than usual time...</object>
                    )}
                </div>
              </div>
              <br />
            </div>
          )}
          {payload.loadType === "Delete LetterHead" && (
            <>
              <p className="">
                Are you sure you want to delete this letterHead ?
                {/* <span className="text-primary">"{payload.amenity_name}"</span>? */}
              </p>
              <div className="text-end">
                <Button
                  variant="falcon-warning"
                  className="me-3 "
                  size="md"
                  onClick={handleDeleteLetterHead}
                  style={{ width: 80 }}
                >
                  Yes
                </Button>
                <Button
                  variant="falcon-default"
                  size="md"
                  onClick={handleFinalClose}
                  style={{ width: 80 }}
                >
                  No
                </Button>
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
