import React from "react";
import { Card } from "react-bootstrap";

import { startCase } from "lodash";
import { formatLogInfo, getHostName } from "../../../../helpers/utils";

import axios from "axios";

import Calendar from "../../../../components/common/Calendar";

import Flex from "../../../../components/common/Flex";

import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";

let months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

let fromArray = [
  "manager",
  "owner",
  "pm-dashboard",
  "owner-dashboard",
  "pm-b-board",
  "owner-b-board",
];

const EventsList = ({ from = "" }) => {
  console.log(from);

  const [eventsList, setEventsList] = React.useState([]);

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  let hostname = getHostName();

  const { property_id, property_name } = useParams();

  React.useEffect(() => {
    // setLoader(true);
    getAllEventsData();
  }, []);

  const getTodayItemsFromList = (list = []) => {
    let todayData = [];
    let today = new Date().toDateString();
    // console.log(today);

    todayData = list?.filter((v) => {
      let permitDate = new Date(v.createdAt).toDateString();
      // console.log(permitDate);
      // console.log(today);

      return permitDate <= today;
    });
    // console.log(todayData);
    return todayData;
  };

  const getAllEventsData = () => {
    let p_id = "";
    let link = "/api/property/ReadEvents"; //specific property

    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    ) {
      p_id = property_id;
      if (from === fromArray[2]) {
        link = "/api/property/EventListDashbaord"; //company specific
      }
      // console.log('need pm api!');
    }
    if (userData.role === "unit_owner") {
      p_id = userData.propid;
    }

    //console.log("starting events list");

    axios
      .post(
        `${hostname}${link}`,
        {
          p_id,
          c_id: userData.compid,
          user_id: userData.data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        //console.log(res.data);

        let arr = res.data.Data?.map((v) => {
          let obj = { ...v };
          obj.event_days = obj.eventDays;
          let tmp_date_start = new Date(obj.event_start_date);
          let tmp_date_end = new Date(obj.event_end_date);
          let calendar = {
            day: tmp_date_start.getDate().toString(),
            month: months[tmp_date_start.getMonth()],
          };
          obj.calendar = calendar;
          obj.event_name = startCase(obj.event_name);
          obj.event_organizer = startCase(obj.event_organizer);
          obj.duration = `${
            months[tmp_date_start.getMonth()]
          } ${tmp_date_start.getDate()} - ${
            months[tmp_date_end.getMonth()]
          } ${tmp_date_end.getDate()}`;

          let tmped = obj.event_days[0];
          obj.time = `${tmped?.start_time} ${tmped?.start_timezone} - ${tmped?.end_time} ${tmped?.end_timezone}`;
          obj.event_registration_deadline = formatLogInfo(
            obj.event_registration_deadline,
            true
          );
          if (obj.event_ticket.event_ticket_type !== "Free Ticket") {
            obj.event_paid_price = obj.event_ticket.ticket_options?.filter(
              (v) => v.checked
            )[0]?.price;
            // console.log(obj);
          }

          return obj;
        });

        // console.log(arr);

        // let itemsOfToday = getTodayItemsFromList(arr);

        let itemsOfToday = [];

        arr?.forEach((v) => {
          // if (checkIfItemExpired(v.event_end_date)) upArr.push(v);

          if (!checkIfItemExpired(v.event_end_date)) itemsOfToday.push(v);
        });

        // console.log(itemsOfToday);
        setEventsList(itemsOfToday);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkIfItemExpired = (expiry_date) => {
    if (!expiry_date) return false;

    // ####
    // Convert it to a Date object
    const dateFromBackend = new Date(expiry_date);

    // Get the current date (local time)
    const now = new Date();

    // Create a new Date object representing only the date portion (ignoring the time)
    const backendDateOnly = new Date(
      dateFromBackend.getFullYear(),
      dateFromBackend.getMonth(),
      dateFromBackend.getDate()
    );
    const todayOnly = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );

    // Compare the dates
    if (backendDateOnly < todayOnly) {
      console.log("The date is in the past.");
      return true;
    } else if (backendDateOnly > todayOnly) {
      console.log("The date is in the future.");
      return false;
    } else {
      console.log("The date is today.");
      return false;
    }
  };

  const getPath = (event = {}) => {
    // to={`/owner-portal/events-calender/${event._id}`}

    console.log(event);

    let path = "";

    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    ) {
      if (from === fromArray[4])
        path = `/property/features/${property_id}/${property_name}/events-calender`;
      else
        path = `/property/features/${event.p_id._id}/${event.p_id.property_name}/events-calender`;
    }
    if (userData.role === "unit_owner") {
      path = "/owner-portal/events-calender";
    }
    return path;
  };

  return (
    <div
      style={{
        height: "400px",
        overflowY: "scroll",
        borderBottomLeftRadius: "5px",
        borderBottomRightRadius: "5px",
      }}
      className="scrollbar shadow-sm"
    >
      <Card className="pt-2">
        <div className="px-3 mt-3 d-flex justify-content-between align-items-center">
          {from === "b-board" && <h5>Events Calender </h5>}
          {from !== "b-board" && <h6>Events Calender </h6>}
          {userData.role === "unit_owner" && (
            <Link className="fs--1" to={getPath()}>
              View all events
            </Link>
          )}
        </div>
        <Card.Body
          className={from === "b-board" ? "bg-light" : ""}
          style={{ minHeight: "400px" }}
        >
          <Row className="fs--1">
            {eventsList.length < 1 && (
              <Col
                md={12}
                className="d-flex align-items-center justify-content-center"
                style={{ height: "280px" }}
              >
                <p className="text-center fs-2">
                  There are currently no events scheduled.
                </p>
              </Col>
            )}
            {eventsList?.map((event, index) => (
              <Col key={event._id} md={12} className="h-100 ms-3">
                <Event
                  event={event}
                  isLast={index === eventsList.length - 1}
                  userData={userData}
                  getPath={getPath}
                />
              </Col>
            ))}
          </Row>
          {userData.role === "unit_owner" && (
            <Link
              to={getPath()}
              style={{
                height: "30px",
                textAlign: "center",
                cursor: "pointer",
                display: "block",
                fontSize: "12px",
              }}
              className="text-primary"
            >
              View All Events
            </Link>
          )}
        </Card.Body>
      </Card>
    </div>
  );
};

export default EventsList;

function Event({ event = {}, getPath }) {
  // console.log(userData);
  return (
    <Flex className="position-relative">
      <Calendar {...event.calendar} />
      {/* <DatePoster /> */}

      <div className="flex-1 position-relative ps-3">
        <h6 className="fs-0 mb-2">
          <span className="text-primary">
            <Link
              className="me-1 mb-2"
              // to={`/owner-portal/events-calender/${event._id}`}
              to={getPath(event)}
            >
              {event.event_name}
            </Link>

            {/* <span className={`badge badge-success rounded-pill `}> */}
            {event.event_ticket?.event_ticket_type === "Free Ticket" && (
              <span className={`badge bg-success`}>Free</span>
            )}
          </span>
        </h6>
        <p className="mb-1">
          <b>Organized by:</b>
          <Link to="#!" className="text-700 ps-1 mb-2">
            {event.event_organizer}
          </Link>
        </p>
        <p className="text-1000 mb-0">
          <b>Time:</b> {event.time}
        </p>

        {event.duration && (
          <p className="text-1000 mb-0">
            <b>Duration:</b> {event.duration}
          </p>
        )}
        {event.duration && (
          <p className="text-1000 mb-0">
            <b>Days:</b> {event.event_days?.length}{" "}
          </p>
        )}
        {/* {interested && <p className="text-1000 mb-0">{interested}</p>} */}
        {event.venue && (
          <p className="text-1000 mb-0">
            <b>Venue:</b> {event.venue}
          </p>
        )}

        {!event.isLast && (
          <div className="border-dashed border-bottom my-5"></div>
        )}
      </div>
    </Flex>
  );
}
