import React, { useState } from "react";

import {
  marketShare,
  totalOrder,
  totalSales,
  // weeklySalesData,
  weather,
  products,
  storageStatus,
  // files,
  // users,
  // topProducts,
  runningProjects,
} from "../../../data/dashboard/default";

// import MailOutRateCard from "./MailOutRateCard";
import TotalOrder from "./TotalOrder";
import PropertiesUnits from "./PropertiesUnits";
import AmenitiesComp from "./AmenitiesComp";
import RecentPropertiesTable from "./RecentPropertiesTable";
import StorageStatus from "./StorageStatus";
import SpaceWarning from "./SpaceWarning";
import OpenMaintainceReqs from "./OpenMaintainceReqs";
// import SharedFiles from "./OwnerFiles/BulletinPosts";
// import ActiveUsers from "./ActiveUsers";
// import BandwidthSaved from "./BandwidthSaved";
// import TopProducts from "./TopProducts";
import LastSixMonthsMaintenanceReqs from "./LastSixMonthsMaintenanceReqs";
import Weather from "./Weather";
import { revenueArr } from "../../../data/dashboard/default";
import axios from "axios";

import { Row, Col } from "react-bootstrap";

import Cookies from "universal-cookie";

import { useParams } from "react-router-dom";

import EConsentModal from "../../pages/sharedUI/EConsentModal";

import { OwnerEventsList, OwnerBulletinPostList } from "./OwnerFiles";

import MaintenanceRequestsCard from "./MaintenanceRequestsCard/MaintenanceRequestsCard";

import Loader from "../../../components/common/Loader";
import jwtDecode from "jwt-decode";
import { getHostName } from "../../../helpers/utils";
import WelcomeUser from "./WelcomeUser";

const Dashboard = () => {
  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");

  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { unit_id } = useParams();

  let hostname = getHostName();

  const [showEConsentModal, setShowEConsentModal] = useState(
    // userData.role === "unit_owner" ? true : false
    false
  );

  const [eConsentData, setEconsentData] = React.useState({});

  const handleCloseEConsentModal = () => setShowEConsentModal(false);
  const handleShowEConsentModal = () => setShowEConsentModal(true);

  const [recentProperties, setRecentProperties] = React.useState([]);

  const [mailoutRate, setMailoutRate] = React.useState({
    ReadBy: 0,
    TotalReceivers: 0,
    Percentage: 0,
  });
  const [reqRate, setReqRate] = React.useState({ rate: 0, performance: 0.0 });

  const [propertiesUnitsData, setPropertiesUnitsData] = React.useState({
    self_occupied: 0,
    vacant: 0,
    rented: 0,
  });

  const [documentDriveStorageData, setDocumentDriveStorageData] =
    React.useState({ storageStatus: [], storageObj: {} });

  const [amenityRevenueData, setAmenityRevenueData] = React.useState([]);
  const [maintenanceRequestData, setMaintenanceRequestData] = React.useState(
    []
  );
  const [maintenanceRequestSixMonthsData, setMaintenanceRequestSixMonthsData] =
    React.useState([]);

  const [weatherData, setWeatherData] = React.useState({});

  const [loader, setLoader] = React.useState(false);

  React.useEffect(() => {
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 1000);

    if (userData.role === "unit_owner") {
      getEConsentValueFromDB();
    }

    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    ) {
      // here
      getPropertyManagerDashboardData();
    }
    getOpenMailRate(); // terminated
    // getOpenReqs();
    getWeather();
    getRecentProperties();
  }, []);

  const getEConsentValueFromDB = () => {
    axios
      .post(
        `${hostname}/api/property/ShowEConsent`,
        {
          user_id: userData.data,
          unit_id: unit_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        setEconsentData(res.data?.data);
        // console.log(res.data?.data?.eConsentShown);
        if (res.data?.data?.eConsentShown) handleShowEConsentModal();
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getOpenMailRate = () => {
    axios
      .post(
        `${hostname}/api/property/mailoutOpenRate`,
        {
          c_id: userData.compid,
          user_id: userData.data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);
        let obj = res.data.Data;

        setMailoutRate({
          ReadBy: obj?.ReadBy || 0,
          TotalReceivers: obj?.TotalReceivers || 0,
          Percentage: obj?.Percentage || 0,
          PropertyName: obj?.PropertyName || 0,
          MailSubject: obj?.MailSubject || 0,
          MailType: obj?.MailType || 0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getWeather = async () => {
    const response = await axios.get("https://ipinfo.io/json");
    const data = response.data;

    // // console.log(data);

    const response2 = await axios.post(
      `${hostname}/api/property/getweather`,
      { data },
      {
        headers: {
          Authorization: `Bearer ${jwtToken}`,
        },
      }
    );
    const data2 = response2.data;

    // console.log(data2);

    setWeatherData(data2.Data);
  };

  const getOpenReqs = () => {
    axios
      .post(
        `${hostname}/api/property/OpenMaintenanceR`,
        {
          c_id: userData.compid,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        let arr = res.data.Data;
        let arr2 = arr.map((v) => v.subject);
        let arr3 = [];
        arr3 = arr3.fill(1);

        if (arr.length > 0)
          setReqRate({
            rate: arr.length,
            performance: arr.length,
            labels: arr2,
            data: arr3,
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getRecentProperties = () => {
    axios
      .post(
        `${hostname}/api/property/getRecentPorperties`,
        {
          c_id: userData.compid,
          user_id: userData.data,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        //console.log(res.data);
        let arr = [...res.data.Data];
        // let arr2 = [];
        // if (arr.length > 5) {
        //   arr2 = arr?.slice(0, 5);
        //   arr2 = arr2?.reverse();
        // }

        // console.log(arr);

        setRecentProperties(arr.reverse());
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const formatBytesValueOnly = (bytes, decimals = 2) => {
    if (bytes === 0) return 0;

    const k = 1048576;

    return parseFloat(bytes / k).toFixed(2);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return 0;

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const getPropertyManagerDashboardData = async () => {
    //config for api

    let config = {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    };

    // open violations tracking notices
    try {
      const openViolationsTrackingNotices = await axios.post(
        `${hostname}/api/property/OpenMaintenanceR`,
        {
          c_id: userData.compid,
        },
        config
      );

      // console.log(openViolationsTrackingNotices);

      let arr = openViolationsTrackingNotices.data.Data;
      let arr2 = arr.map((v) => v.subject);
      let arr3 = [];
      arr3 = arr3.fill(1);

      if (arr.length > 0)
        setReqRate({
          rate: arr.length,
          performance: arr.length,
          labels: arr2,
          data: arr3,
        });
    } catch (e) {
      console.log(e);
    }

    // properties units
    try {
      const propertiesUnits = await axios.post(
        `${hostname}/api/property/PropertyUnitDetials`,
        {
          c_id: userData.compid,
        },
        config
      );

      // console.log(propertiesUnits.data);
      setPropertiesUnitsData(propertiesUnits.data.Data);
    } catch (e) {
      console.log(e);
    }

    // amenity revenue
    try {
      const AmenityRevenue = await axios.post(
        `${hostname}/api/property/AmenityRevenue`,

        {
          c_id: userData.compid,
        },
        config
      );

      //console.log(AmenityRevenue.data);
      let AmenityRevenueArr = AmenityRevenue.data.Data;

      let revenueArrTmp = [...revenueArr];

      AmenityRevenueArr?.forEach((entry) => {
        entry?.date_value.forEach((v) => {
          revenueArrTmp[entry.month_number - 1][v.date] = v.value;
          // console.log(revenueArrTmp[entry.month_number]);
        });
      });

      setAmenityRevenueData(revenueArrTmp);
    } catch (e) {
      console.log(e);
    }

    // document drive storage
    try {
      const documentDriveStorage = await axios.post(
        `${hostname}/api/property/ReadDocdrive`,
        {
          c_id: userData.compid,
        },
        config
      );

      // console.log(documentDriveStorage);
      let ds = documentDriveStorage.data.Data;
      let Total_Space = formatBytesValueOnly(+ds.Total_Space);
      let Free_Space = formatBytesValueOnly(+ds.Total_Space - +ds.Used_Space);
      let Used_Space = formatBytesValueOnly(+ds.Used_Space);

      let storageStatus = [
        {
          name: "Regular",
          size: Used_Space || 895,
          color: "primary",
        },
        // {
        //   name: "System",
        //   size: 379,
        //   color: "info",
        // },
        // {
        //   name: 'Shared',
        //   size: 192,
        //   color: 'success'
        // },
        {
          name: "Free",
          size: Free_Space || 576,
          color: "200",
        },
      ];
      // console.log({
      //   storageStatus,
      //   storageObj: { Total_Space, Used_Space, Free_Space },
      // });
      setDocumentDriveStorageData({
        storageStatus,
        storageObj: { Total_Space, Used_Space, Free_Space },
      });
    } catch (e) {
      console.log(e);
    }

    // open maintenance reqs of last 6 months
    try {
      const openMaintenanceReqsLastSixmonths = await axios.post(
        `${hostname}/api/property/MaintenanceRequest`,
        {
          c_id: userData.compid,
        },
        config
      );

      let topProducts = [
        ["product", "Open", "Closed"],
        ["Boots4", 43, 85],
        ["Reign Pro", 83, 73],
        ["Slick", 86, 62],
        ["Falcon", 72, 53],
        ["Sparrow", 80, 50],
        ["Hideaway", 50, 70],
        ["Freya", 80, 90],
      ];

      // console.log(openMaintenanceReqsLastSixmonths.data);

      let mainDataArr = [];

      openMaintenanceReqsLastSixmonths.data.Data.forEach((v) => {
        mainDataArr.push(["Property", v.Open, v.Closed]);
      });

      // console.log(mainDataArr);

      setMaintenanceRequestSixMonthsData(mainDataArr);
    } catch (e) {
      console.log(e);
    }

    // open maintenance reqs
    try {
      const openMaintenanceReqs = await axios.post(
        `${hostname}/api/property/latestMaintenance`,
        {
          c_id: userData.compid,
        },
        config
      );

      // console.log(openMaintenanceReqs);
      setMaintenanceRequestData(openMaintenanceReqs.data.Data || []);
    } catch (e) {
      console.log(e);
    }
  };

  if (loader) {
    return <Loader />;
  }

  if (
    userData.role === "No role Assigned" ||
    userData.role === "Condozak-Basic-Users" ||
    userData.role === "Site-Administrators"
  )
    return (
      <>
        <Row className="g-3 mb-3">
          <Col md={6} xxl={3}>
            <WelcomeUser userData={userData} />
            {/* <MailOutRateCard data={weeklySalesData} mailoutRate={mailoutRate} /> */}
          </Col>
          <Col md={6} xxl={3}>
            <Weather data={weather} weatherData={weatherData} />
          </Col>
          <Col md={6} xxl={3}>
            <PropertiesUnits
              propertiesUnitsData={propertiesUnitsData}
              data={marketShare}
              radius={["100%", "87%"]}
            />
          </Col>
          <Col md={6} xxl={3}>
            <MaintenanceRequestsCard reqRate={reqRate} />
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col lg={6}>
            <RecentPropertiesTable
              data={runningProjects}
              recentProperties={recentProperties}
            />
          </Col>
          <Col lg={6}>
            <AmenitiesComp data={amenityRevenueData} />
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col lg={6} xl={7} xxl={8}>
            <StorageStatus
              className="h-lg-100"
              data={documentDriveStorageData}
            />
          </Col>
          <Col lg={6} xl={5} xxl={4}>
            <SpaceWarning />
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col lg={6} xl={7}>
            <OpenMaintainceReqs
              openRequests={maintenanceRequestData}
              userData={userData}
            />
          </Col>
          <Col lg={6} xl={5}>
            <OwnerBulletinPostList
              userData={userData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          </Col>
        </Row>

        <Row className="g-3">
          {/* <Col sm={6} xxl={6}>
            <ActiveUsers className="h-100" users={users} />
          </Col>
          <Col sm={6} xxl={6} className="order-xxl-1">
            <BandwidthSaved />
          </Col> */}
          <Col xl={6}>
            {/* <TopProducts data={topProducts} className="h-100" /> */}
            <LastSixMonthsMaintenanceReqs
              data={maintenanceRequestSixMonthsData}
              className="h-100"
            />
          </Col>
          <Col xl={6}>
            <OwnerEventsList from="pm-dashboard"/>
          </Col>
        </Row>

        <EConsentModal
          show={showEConsentModal}
          handleClose={handleCloseEConsentModal}
          userData={userData}
          eConsentData={eConsentData}
          hostname={hostname}
          jwtToken={jwtToken}
        />
      </>
    );
  if (userData.role === "none" || userData.role === "unit_owner")
    return (
      <>
        <Row className="g-3 mb-3">
          <Col md={6} xxl={3}>
            {/* <MailOutRateCard data={weeklySalesData} fromowner="true" /> */}
            <WelcomeUser userData={userData} />
          </Col>
          <Col md={6} xxl={3}>
            <Weather
              data={weather}
              fromowner="true"
              weatherData={weatherData}
            />
          </Col>
          <Col md={6} xxl={3}>
            <PropertiesUnits
              data={marketShare}
              radius={["100%", "87%"]}
              fromowner="true"
            />
          </Col>
          <Col md={6} xxl={3}>
            <TotalOrder data={totalOrder} fromowner="true" />
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col lg={6}>
            <AmenitiesComp data={totalSales} fromowner="true" />
          </Col>
          <Col lg={6}>
            {/* // since this is standalone compnent it manages its own data */}
            <OwnerEventsList from="owner-dashboard"/>
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col lg={6} xl={7} xxl={8}>
            <StorageStatus
              className="h-lg-100"
              data={storageStatus}
              fromowner="true"
            />
          </Col>
          <Col lg={6} xl={5} xxl={4}>
            <SpaceWarning fromowner="true" />
          </Col>
        </Row>

        <Row className="g-3 mb-3">
          <Col lg={7} xl={7}>
            <OpenMaintainceReqs products={products} fromowner="true" />
          </Col>
          <Col lg={5} xl={5}>
            <OwnerBulletinPostList
              userData={userData}
              hostname={hostname}
              jwtToken={jwtToken}
            />
          </Col>
        </Row>

        <EConsentModal
          show={showEConsentModal}
          handleClose={handleCloseEConsentModal}
          userData={userData}
          eConsentData={eConsentData}
          hostname={hostname}
          jwtToken={jwtToken}
        />
      </>
    );
};

export default Dashboard;
