import React from "react";

import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Cookies from "universal-cookie";

const ErrorNotInvited = () => {
  const cookies = new Cookies();

  const { user_exit_role } = useParams();
  let host = process.env.NODE_ENV === "development" ? "http" : "https";

  let link = "login";
  if (
    user_exit_role === "property_invited_user" ||
    user_exit_role === "unit_owner_multi_invited" ||
    user_exit_role === "access_revoked_ologin"
  ) {
    link = "property/login";
  }

  React.useEffect(() => {
    cookies.remove("_expire_t");
    cookies.remove("_expire_l");
  }, []);

  return (
    <Card className="text-center">
      <Card.Body className="px-5">
        <div className="display-4 text-400 mt-3">We Are Sorry !</div>

        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          You don't have access to <br /> <br />
          <a
            className="text-warning"
            href={`${host}://${window.location.host}/${link}`}
          >
            {`${host}://${window.location.host}/${link}`}
          </a>
        </p>
        <p className="text-warning fw-semi-bold fs-1"></p>
        <hr />
        <p>
          If you want to join this{" "}
          {user_exit_role === "company_invited_login" ||
          user_exit_role === "access_revoked_login" ||
          user_exit_role === "access_revoked_ologin"
            ? "Company"
            : " community"}
          , please contact your site-admin for assistance.
        </p>
      </Card.Body>
    </Card>
  );
};

export default ErrorNotInvited;
