import classNames from "classnames";
import Flex from "../../common/Flex";
import SoftBadge from "../../common/SoftBadge";
// import PropTypes from "prop-types";
import React from "react";
import {
  Button,
  Card,
  Col,
  Form,
  // ProgressBar,
  Row,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const getTotalPrice = (items) =>
  items
    .map(({ unit, price }) => unit * price)
    .reduce((total, currentValue) => total + currentValue, 0);

const getProductItemCalculatedData = (unit, price, totalPrice) => {
  const productTotalPrice = unit * price;
  const percentage = ((productTotalPrice * 100) / totalPrice).toFixed(0);
  return { productTotalPrice, percentage };
};

const RequestRow = ({ request, totalPrice, isLast, fromowner, getPath }) => {
  // const { img, title, type, unit, price,p_name, status, request } = request;
  const { title, type, unit, price, p_name, status } = request;
  // const { productTotalPrice, percentage } = getProductItemCalculatedData(
  // const { percentage } = getProductItemCalculatedData(unit, price, totalPrice);
  if (fromowner)
    return (
      <tr className={classNames({ "border-bottom border-200": !isLast })}>
        <td>
          <Flex alignItems="center" className="position-relative">
            {/* <img
          className="rounded-1 border border-200"
          src={img}
          width="60"
          alt={title}
        /> */}
            <div className="ms-3">
              <h6 className="mb-1 fw-semi-bold">
                <Link className="text-dark stretched-link" to="#!">
                  {request}
                </Link>
              </h6>
              <p className="fw-semi-bold mb-0 text-500">{type}</p>
            </div>
          </Flex>
        </td>
        <td className="align-middle text-center fw-semi-bold">
          {/* ${productTotalPrice} */}
          <SoftBadge bg={status.type}>{status.title}</SoftBadge>
        </td>
        <td className="text-end">
          {/* <Flex alignItems="center"> */}
          {/* <ProgressBar now={percentage} style={{ width: '80px', height: 5 }} /> */}
          <div className="fw-semi-bold ms-3">{p_name}</div>
          {/* <div className="fw-semi-bold ms-3">{percentage}%</div> */}
          {/* </Flex> */}
        </td>
      </tr>
    );

  return (
    <tr className={classNames({ "border-bottom border-200": !isLast })}>
      <td>
        <Flex alignItems="center" className="position-relative">
          {/* <img
            className="rounded-1 border border-200"
            src={img}
            width="60"
            alt={title}
          /> */}
          <div className="ms-3">
            <h6 className="mb-1 fw-semi-bold ">
              <FontAwesomeIcon
                icon="circle"
                className={`fs--1 me-3 text-success`}
              />
              <Link className="text-dark stretched-link" to={getPath(request)}>
                {request.request_subject}
              </Link>
            </h6>
            <p className="fw-semi-bold mb-0 text-500">{type}</p>
          </div>
        </Flex>
      </td>
      <td className="align-middle text-center fw-semi-bold">
        {/* ${productTotalPrice} */}
        {request.property_name}
      </td>
      <td className="text-end">
        {/* <Flex alignItems="center"> */}
        {/* <ProgressBar now={percentage} style={{ width: '80px', height: 5 }} /> */}
        <div className="fw-semi-bold ms-3">{request.unit_number}</div>
        {/* <div className="fw-semi-bold ms-3">{percentage}%</div> */}
        {/* </Flex> */}
      </td>
    </tr>
  );
};

const OpenMaintainceReqs = ({ openRequests = [], fromowner, userData }) => {
 // console.log(typeof openRequests);
  // const totalPrice = getTotalPrice(openRequests);

  const getPath = (request = {}) => {
    // to={`/owner-portal/requests-calender/${request._id}`}

   // console.log(request);

    let path = "";

    if (
      userData.role === "Site-Administrators" ||
      userData.role === "Condozak-Basic-Users"
    ) {
      path = `/property/features/${request.p_id}/${request.property_name}/service-requests`;
    }
    if (userData.role === "unit_owner") {
      path = "/owner-portal/maintenance-requests";
    }
    return path;
  };

  const noOfRequests = openRequests.length;

  if (typeof openRequests !== "object") return null;
  if (fromowner)
    return (
      <Card className="h-lg-100 overflow-hidden">
        <Card.Body className="p-0 ">
          <Table borderless responsive className="mb-0 fs--1">
            <thead className="bg-light">
              <tr className="text-900">
                <th>Open / Pending Requests</th>
                {/* <th className="text-end">Property Name ( ${totalPrice})</th> */}
                <th className="text-center">Status</th>
                <th className="pe-card text-end" style={{ width: "8rem" }}>
                  Assigned To
                </th>
              </tr>
            </thead>
            <tbody>
              {openRequests?.map((request, index) => (
                <RequestRow
                  fromowner={fromowner}
                  request={request}
                  // totalPrice={totalPrice}
                  isLast={index === noOfRequests - 1}
                  key={request._id}
                />
              ))}
            </tbody>
          </Table>
        </Card.Body>
        <Card.Footer className="bg-light py-2">
          <Row className="flex-between-center">
            <Col xs="auto">
              <Form.Select size="sm" className="me-2">
                <option>Last 7 days</option>
                <option>Last Month</option>
                <option>Last Year</option>
              </Form.Select>
            </Col>
            <Col xs="auto">
              <Button variant="falcon-default" size="sm" as={Link} to="#!">
                View All
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    );

  return (
    <Card className="h-lg-100 ">
      <Card.Body className="p-0" style={{ minHeight: "320px" }}>
        <Table borderless responsive className="mb-0 fs--1">
          <thead className="bg-light">
            <tr className="text-900">
              <th>Latest Open Maintenance Requests</th>
              {/* <th className="text-end">Property Name ( ${totalPrice})</th> */}
              <th className="text-center">Property Name</th>
              <th className="pe-card text-end" style={{ width: "8rem" }}>
                Unit No.
              </th>
            </tr>
          </thead>
          <tbody>
            {typeof openRequests === "object" &&
              openRequests?.map((request, index) => (
                <RequestRow
                  request={request}
                  // totalPrice={totalPrice}
                  isLast={index === noOfRequests - 1}
                  key={request._id + index + "kh"}
                  getPath={getPath}
                />
              ))}
            {openRequests.length < 1 && (
              <>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={3} className="text-center lead">
                    Nothing found!
                  </td>
                </tr>
                <tr>
                  <td></td>
                </tr>
                <tr>
                  <td></td>
                </tr>
              </>
            )}
          </tbody>
        </Table>
      </Card.Body>
      {/* <Card.Footer className="bg-light py-2">
        <Row className="">
          <Col xs="auto">
            <Form.Select size="sm" className="me-2">
              <option>Last 7 days</option>
              <option>Last Month</option>
              <option>Last Year</option>
            </Form.Select>
          </Col>
          <Col className="d-flex justify-content-center align-items-center">
            <Button variant="link" size="sm" as={Link} to="#!">
              View All
            </Button>
          </Col>
        </Row>
      </Card.Footer> */}
    </Card>
  );
};

export default OpenMaintainceReqs;
