import React from "react";
// import PropTypes from 'prop-types';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from "../../../components/common/Flex";
import SoftBadge from "../../../components/common/SoftBadge";
import { Badge } from "react-bootstrap";
import axios from "axios";
import Cookies from "universal-cookie";
import { getHostName } from "../../../helpers/utils";

const NavbarVerticalMenuItem = ({ route, userData = {} }) => {
  const cookies = new Cookies();
  let jwtToken = cookies.get("_expire_t");
  let hostname = getHostName();
  const [emailCounter, setEmailCounter] = React.useState(0);

  const getAllInboxMailStatus = () => {
    //call backend
    //console.log("loading inbox items...");
    axios
      .post(
        `${hostname}/api/property/UnreadMailsCounter`,

        {
          user_id: userData.data,
          p_id: userData.propid,
          unit_id: userData.UnitsOwner[0]._id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setEmailCounter(res.data.Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    let id = "";
    if (userData.role === "unit_owner" && route.name === "Inbox") {
      getAllInboxMailStatus();

      id = setInterval(() => {
        if (userData.role === "unit_owner" && route.name === "Inbox")
          getAllInboxMailStatus();
      }, 5000);
    }
    return () => {
      if (userData.role === "unit_owner" && route.name === "Inbox") {
        clearInterval(id);
      }
    };
  }, []);

  return (
    <Flex alignItems="center" justifyContent="">
      {route.icon && (
        <span className="nav-link-icon">
          {/* <FontAwesomeIcon icon={route.icon} /> */}
          {route.icon}
        </span>
      )}
      <span className="nav-link-text ps-2" style={{ marginTop: "3px" }}>
        {route.name}
        {userData.role === "unit_owner" &&
          emailCounter > 0 &&
          route.name === "Inbox" && (
            <Badge className="ms-2 bg-warning">{emailCounter}</Badge>
          )}
      </span>
      {route.badge && (
        <SoftBadge pill bg={route.badge.type} className="ms-2">
          {route.badge.text}
        </SoftBadge>
      )}
    </Flex>
  );
};

// prop-types
// const routeShape = {
//   active: PropTypes.bool,
//   name: PropTypes.string.isRequired,
//   to: PropTypes.string,
//   icon: PropTypes.oneOfType([PropTypes.array, PropTypes.string])
// };
// routeShape.children = PropTypes.arrayOf(PropTypes.shape(routeShape));
// NavbarVerticalMenuItem.propTypes = {
//   route: PropTypes.shape(routeShape).isRequired
// };

export default React.memo(NavbarVerticalMenuItem);
