import React from "react";
import { Card } from "react-bootstrap";
import Flex from "../../../common/Flex";
import { Button } from "react-bootstrap";
import buildingEmail from "../../../../assets/img/logos/buildingEmail.png";
// import Cookies from "universal-cookie";

export default function InvitationSingle({
  invitation,
  acceptInvitationData,
  ignoreInvitationData,
  acceptOwnerInvite,
  ignoreOwnerInvite,
  userData = {},
}) {
  console.log(invitation);
  console.log(userData);
  // console.log(props);

  if (userData.role === "No role Assigned")
    //company
    return (
      <Card className="">
        <Card.Body>
          {/* <p style={{ fontSize: "14px", fontWeight: "bold" }}>
            Invitation from {invitation?.company_name || "company name"}
          </p> */}
          <h6 className="text-center">You have been invited!</h6>

          <Flex justifyContent={"between"} alignItems="center">
            <div>
              <img
                src={buildingEmail}
                alt=""
                className="img-fluid rounded"
                width="250"
              />
            </div>
            <div className="ms-5">
              <p style={{ fontSize: "14px" }}>
                Hi,{" "}
                <span className="text-primary fw-bold">
                  {invitation?.invitee || "FirstName LastName"}
                </span>{" "}
                has invited you to join{" "}
                <span className="text-primary fw-bold">
                  {invitation?.company_name || "Abt Associations Corp."}
                </span>{" "}
                as the
                <span className="fw-bold">
                  {" "}
                  {invitation?.groups[0] === "Condozak-Basic-Users"
                    ? "Condozak-Users"
                    : invitation?.groups[0] || "Basic Condozak User Group"}
                </span>
              </p>
              <Button
                variant="success"
                size="sm"
                className="me-2"
                onClick={() =>
                  acceptInvitationData(
                    invitation?.c_id,
                    invitation?.groups[0],
                    invitation?.bgColor,
                    invitation.company_name
                  )
                }
              >
                Accept
              </Button>
              <Button
                variant="secondary"
                size="sm"
                onClick={() =>
                  ignoreInvitationData(
                    invitation?.c_id,
                    invitation?.groups,
                    invitation?.bgColor,
                    invitation.company_name
                  )
                }
              >
                Ignore
              </Button>
            </div>
          </Flex>
        </Card.Body>
      </Card>
    );

  if (userData.role === "none" || userData.role === "unit_owner")
    return (
      //owner side
      <Card className="mb-3">
        <Card.Body>
          {/* <p style={{ fontSize: "14px" }}>
            Invitation from{" "}
            <span className="text-primary fw-bold">
              {invitation?.property_name || "prop name"}
            </span>
          </p> */}
          <h6 className="text-center">You have been invited!</h6>
          <br />
          <Flex justifyContent={"between"} alignItems="center">
            <div>
              <img
                src={buildingEmail}
                alt=""
                className="img-fluid rounded"
                width="100"
              />
            </div>
            <div className="ms-5">
              <p style={{ fontSize: "14px" }}>
                Hi,{" "}
                <span className="text-primary fw-bold mb-2">
                  {invitation?.firstname || "FirstName"}{" "}
                  {invitation?.lastname || "LastName"}
                </span>{" "}
                <br />
                has invited you to join
                <br />
                unit{" "}
                <span className="text-primary fw-bold">
                  {invitation?.unit_number || "Abt Associations Corp."}
                </span>{" "}
                as{" "}
                <span className="text-primary fw-bold">
                  {Object.hasOwnProperty.call(invitation, "group") &&
                    invitation.group === "Unit Owner" &&
                    "Unit Owner"}
                  {Object.hasOwnProperty.call(invitation, "group") &&
                    invitation.group === "Supplementary" &&
                    "Supplementary Owner"}
                  {Object.hasOwnProperty.call(invitation, "group") &&
                    invitation.group === "Tenant" &&
                    "Primary Tenant"}
                  {Object.hasOwnProperty.call(invitation, "group") &&
                    invitation.group === "Supplementary Tenant" &&
                    "Supplementary Tenant"}
                  {!Object.hasOwnProperty.call(invitation, "group") && "Owner"}{" "}
                  {/* {invitation.groups[0] || 'Basic Condozak User Group'} */}
                </span>
              </p>
              <Button
                variant="success"
                size="sm"
                className="me-2"
                onClick={() => acceptOwnerInvite(invitation)}
              >
                Accept
              </Button>
              <Button
                variant="secondary"
                size="sm"
                onClick={() => ignoreOwnerInvite(invitation)}
              >
                Ignore
              </Button>
            </div>
          </Flex>
        </Card.Body>
      </Card>
    );
}
