import React from "react";

import { Card, Row, Col, Button } from "react-bootstrap";

import CustomSearchbar from "components/common/CustomSearchbar";

import Flex from "components/common/Flex";

import { Link, useNavigate } from "react-router-dom";

import InboxTable from "./InboxTable";

import InboxModal from "./InboxModal";

import Loader from "components/common/Loader";

// import EmailDetail from "./EmailDetail";

import axios from "axios";

import { formatLogInfo } from "helpers/utils";

import RefreshIcon from "@mui/icons-material/Refresh";

import JSZip from "jszip";

import saveAs from "file-saver";

import { toast } from "react-toastify";

import date from "date-and-time";

import { PDFDocument, rgb, StandardFonts, PDFPage, PDFName } from "pdf-lib";

import { convert } from "html-to-text";

import { Delete, Download } from "@mui/icons-material";

import { getHtmlWithImageActualData } from "helpers/utils-pdf";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

import htmlToPdfmake from "html-to-pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import pdfMake from "pdfmake/build/pdfmake";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function EmailInbox() {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const navigate = useNavigate();

  //main data
  const [emailsList, setEmailsList] = React.useState([]);
  const [emailsListTemp, setEmailsListTemp] = React.useState([]);

  const [bulkMails, setBulkMails] = React.useState([]);
  console.log(bulkMails);

  //main modal
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //main action
  const [loader, setLoader] = React.useState(false);
  const [payload, setPayload] = React.useState({});

  // const [showSingleMailDetails, setShowSingleMailDetails] = React.useState({
  //   isShown: false,
  //   emailPayload: {},
  // });

  React.useEffect(() => {
    setLoader(true);
    getAllEmailsListData();
  }, []);

  const getAllEmailsListData = () => {
    axios
      .post(
        `${hostname}/api/property/ReadUserInbox`,
        {
          user_id: userData.data,
          p_id: userData.propid,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        let arr = res.data?.Data?.map((v, i) => {
          let mail_content_pure = v.mail_content;
          mail_content_pure = mail_content_pure?.replace(/(\r\n|\n|\r)/gm, "");
          // console.log(mail_content_pure);
          // console.log(v.inbox_mail_type);
          if (
            v.inbox_mail_type === "visitorparking" ||
            v.inbox_mail_type === "visitormanagement" ||
            v.inbox_mail_type === "violation"
          ) {
            mail_content_pure = mail_content_pure?.replace(
              "font-family: Helvetica, Arial, sans-serif; ",
              ""
            );
            mail_content_pure = mail_content_pure?.replace(
              "text-decoration: none; ",
              ""
            );
          }

          let oldObj = {
            id: "mail-" + (i + 1),
            mail_id: v._id,
            user: "no-reply ",
            img: "",
            title: v.mail_subject,
            descriptionFull: convert(v.mail_content, { wordwrap: 0 }),
            // descriptionFullHTML: v.mail_content,
            descriptionFullHTML: mail_content_pure,
            attachments: v.attachments,
            time: formatLogInfo(v.updatedAt, true),
            timeFull: formatLogInfo(v.updatedAt),
            sender_name: v.sender_first_name + " " + v.sender_last_name,
            sender_email: v.sender_email,
            star: false,
            snooze: false,
            read: v.isRead,
            deleted: v.isDeleted,
            archived: v.isArchive,
            // createdAt: v.createdAt,
          };
          oldObj.description =
            oldObj.descriptionFull?.length > 95
              ? oldObj.descriptionFull?.slice(0, 95) + "..."
              : oldObj.descriptionFull;
          return oldObj;
        });

        console.log(arr);
        // arr.sort((a, b) => new Date(a.timeFull) - new Date(b.timeFull));
        // ##Abdullah
        // arr.sort((a, b) =>
        //   a.timeFull < b.timeFull ? 1 : b.timeFull < a.timeFull ? -1 : 0
        // );

        setEmailsList(arr);
        setEmailsListTemp(arr);
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //display single email detail
  const displaySingleEmailData = (obj = {}) => {
    console.log(obj);
    navigate(`/owner-portal/inbox/${obj.mail_id}`);
    // setShowSingleMailDetails({ isShown: true, emailPayload: obj });
  };

  //archive, delete or unread
  const callGivenOption = (optionName = "", optionValue, obj = {}) => {
    // close single mail detail if open

    //processing
    // setLoader(true);
    let readIndex = 0;
    let readObj = emailsList.filter((v, i) => {
      if (v.id === obj.id || v.mail_id === obj.mail_id) {
        readIndex = i;
      }
      return v.id === obj.id || v.mail_id === obj.mail_id;
    });

    console.log(readObj.mail_id);

    // delete
    if (optionName === "delete") {
      let allMail = emailsList.filter((v) => v.mail_id !== obj.mail_id);
      axios
        .post(
          `${hostname}/api/property/UpdateInbox`,
          {
            inbox_id: obj.mail_id,
            isArchive: obj.archived,
            isDeleted: true,
            isRead: obj.read,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setEmailsList(allMail);
          setEmailsListTemp(allMail);
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });

      toast.warning("messages deleted", { theme: "colored" });
    }

    // unread
    if (optionName === "unread") {
      let allMail = [...emailsList];
      allMail[readIndex].read = false;

      axios
        .post(
          `${hostname}/api/property/UpdateInbox`,
          {
            inbox_id: obj.mail_id,
            isArchive: obj.archived,
            isDeleted: obj.deleted,
            isRead: false,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setEmailsList([...allMail]);
          setEmailsListTemp([...allMail]);
          setLoader(false);
          toast.success("action applied.", { theme: "colored" });
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // archive + undo
    if (optionName === "archive") {
      let allMail = [...emailsList];
      allMail[readIndex].archived = optionValue;

      axios
        .post(
          `${hostname}/api/property/UpdateInbox`,
          {
            inbox_id: obj.mail_id,
            isArchive: optionValue,
            isDeleted: obj.deleted,
            isRead: obj.read,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setEmailsList([...allMail]);
          setEmailsListTemp([...allMail]);
          setLoader(false);
          toast.success("action applied.", { theme: "colored" });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const addBulkAction = (v) => {
    console.log(v);
    if (bulkMails.includes(v)) {
      let state = bulkMails?.filter((j) => j !== v);
      setBulkMails(state);
    } else {
      setBulkMails(bulkMails.concat(v));
    }
  };

  function generateBase64Pdf(docDefinition) {
    return new Promise((resolve, reject) => {
      const pdfDoc = pdfMake.createPdf(docDefinition);
      pdfDoc.getBlob((result) => {
        resolve(result); // Resolve the promise with the Base64 result
      });
    });
  }

  const applyBulkAction = async (actionType = "") => {
    setLoader(true);

    console.log(bulkMails);

    if (bulkMails.length > 0) {
      console.log("call api here..");
      let arr = emailsList?.filter((v) => bulkMails.includes(v.id));

      console.log(arr);
      // return;

      if (actionType === "download") {
        let mails = [];
        arr?.forEach((v) => {
          console.log(v);
          let obj = {};
          obj.mail_pdf = v.descriptionFullHTML;
          obj.user_name = v.sender_name;

          mails.push(obj);
        });

        console.log(mails);

        let bulkData = [];

        for (let index = 0; index < mails.length; index++) {
          const element = mails[index];
          console.log(element);
          let file = "";
          let content = "";
          if (!element.mail_pdf) {
            continue;
          }

          console.log(file);
          console.log("working");

          content = await getHtmlWithImageActualData(element.mail_pdf);

          console.log(content);

          let htmlContent = htmlToPdfmake(content, {
            defaultStyles: { p: { margin: [0, 0, 0, 0] } },
            removeExtraBlanks: true,
          });

          let docDefinition = {
            // meta info
            info: {
              title: "mail",
              author: "",
              subject: "",
              keywords: "",
            },

            pageSize: "A4",

            // [left, top, right, bottom] or [horizontal, vertical] or just a number
            pageMargins: [40, 60, 40, 60],

            content: htmlContent,
          };

          bulkData.push({ docDefinition, user_name: element.user_name });
        }

        console.log(bulkData);

        let promises = [];

        // Generate Base64 PDF for each document definition
        for (let i = 0; i < bulkData.length; i++) {
          promises.push(generateBase64Pdf(bulkData[i].docDefinition));
        }

        // Wait for all promises to resolve
        Promise.all(promises)
          .then((base64PdfArray) => {
            console.log(base64PdfArray); // Array with Base64 PDFs
            // Now you can send `base64PdfArray` back to the caller function or do other processing
            const zip = new JSZip();

            base64PdfArray.forEach((file, i) => {
              // if (file?.startsWith("data")) file = file?.split("base64,")[1];

              // const blob = b64toBlob(file, "application/pdf");

              // bulkData.push(blob);

              console.log("####################################");
              console.log("####################################");
              console.log("####################################");
              console.log(file);

              // zip.file(bulkData[i].user_name + i + ".pdf", file);
              zip.file(`${bulkData[i].user_name}-${i + 1}.pdf`, file);
            });

            console.log(zip);

            // once you finish adding all the pdf to the zip, return the zip file
            zip.generateAsync({ type: "blob" }).then((blob) => {
              console.log(zip.files);
              saveAs(
                blob,
                date.format(new Date(), "YYYYMMDD") +
                  "_" +
                  userData.property_name +
                  ".zip"
              );
              setLoader(false);
            });
          })
          .catch((error) => {
            console.error("Error generating PDFs:", error);
          });

        return;
      }
      if (actionType === "delete") {
        console.log("delete");
        let arr = [];
        let arrToDelete = [];
        arr = emailsList.filter((v) => !bulkMails.includes(v.id));
        arrToDelete = emailsList.filter((v) => bulkMails.includes(v.id));
        console.log(arr);
        setLoader(false);

        arrToDelete?.forEach((v) => {
          callGivenOption("delete", true, v);
        });
      }
      setBulkMails([]);
    } else {
      console.log("no files to convert");
    }
  };

  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  };

  const refreshTable = () => {
    setLoader(true);
    getAllEmailsListData();
  };

  const printPages = async (
    textToWrite = "",
    oldpdfExists = false,
    existingPdfBytes,
    downloadMode = false
  ) => {
    console.log(textToWrite);
    console.log(existingPdfBytes);
    console.log(oldpdfExists);

    if (oldpdfExists) {
      //create new doc
      const pdfDocVar = await PDFDocument.load(existingPdfBytes);
      const TRFont = await pdfDocVar.embedFont(StandardFonts.TimesRoman);

      let textVar = convert(textToWrite, { wordwrap: 101 });
      console.log(textVar.length); //1079

      let textArr = [];
      let counter = 0;
      // return;

      let pagesLengthVar = textVar.length / 2565;
      console.log(pagesLengthVar);
      pagesLengthVar = Math.ceil(pagesLengthVar);
      //pagesLengthVar--;
      console.log(pagesLengthVar);
      let lengthForPages =
        pagesLengthVar > 1 ? pagesLengthVar - 1 : pagesLengthVar;
      console.log(lengthForPages);

      // if (lengthForPages > 1) {
      //   for (let i = 0; i < lengthForPages; i++) {
      if (pagesLengthVar > 1) {
        for (let i = 0; i < pagesLengthVar - 1; i++) {
          let pageToAdd = await copyPage(pdfDocVar.getPage(0));
          await pdfDocVar.addPage(pageToAdd);
          console.log(i);
          // let textTmp = textVar.slice(counter, counter + 2005);
          // textArr.push(textTmp);
          // counter = 2005;
        }
      }
      for (let i = 0; i < pagesLengthVar; i++) {
        // pdfDocVar.addPage();
        // console.log(i);
        let textTmp = textVar?.slice(counter, counter + 2015);
        textArr.push(textTmp);
        counter = 2015;
      }

      pdfDocVar.getPages().forEach((v, i) => {
        let page = v;

        const { height } = page.getSize();
        if (textArr[i])
          page.drawText(textArr[i], {
            x: 53,
            y: height / 2 + 270,
            size: 12,
            lineHeight: 16,
            font: TRFont,
            color: rgb(0, 0, 0),
          });
      });

      const pdfBytesVar = await pdfDocVar.save();

      console.log(pdfBytesVar);

      let binaryVar = "";

      for (let i = 0; i < pdfBytesVar.length; i++) {
        binaryVar += String.fromCharCode(pdfBytesVar[i]);
      }
      binaryVar = btoa(binaryVar);

      let pdfVar = await b64toBlob(binaryVar, "application/pdf");

      // let content_pdfVar = `data:application/pdf;base64,${binaryVar}`;

      if (downloadMode) {
        const pdfUrl = URL.createObjectURL(pdfVar);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "document.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        await window.open(URL.createObjectURL(pdfVar));
      }
    } else {
      console.log("345678999999999999999999dxfcgvhbjnklcfgvbhj");
      //create new doc
      const pdfDocVar = await PDFDocument.create();
      const TRFont = await pdfDocVar.embedFont(StandardFonts.TimesRoman);

      let textVar = convert(textToWrite, { wordwrap: 101 });
      // let textVar = textToWrite;
      console.log(textVar.length); //1079

      let textArr = [];
      let counter = 0;
      // return;

      let pagesLengthVar = textVar.length / 2565;

      pagesLengthVar = Math.ceil(pagesLengthVar);

      console.log(pagesLengthVar);

      for (let i = 0; i < pagesLengthVar; i++) {
        pdfDocVar.addPage();
        console.log(i);
        let textTmp = textVar.slice(counter, counter + 2565);
        textArr.push(textTmp);
        counter = 2565;
      }

      console.log(textArr);

      console.log(pdfDocVar.getPages());

      pdfDocVar.getPages().forEach((v, i) => {
        let page = v;
        // Get the width and height of the first page
        const { height } = page.getSize();
        // let textIn = textArr.length / pagesLengthVar;
        // let textIn = textVar.slice(0,2665);
        // let textIn = textArr.slice(0,500);
        // textIn = textIn.join(' ');
        // console.log(textIn);
        // Draw a string of text diagonally across the first page
        page.drawText(textArr[i], {
          x: 53,
          y: height / 2 + 360,
          size: 12,
          lineHeight: 16,
          font: TRFont,
          color: rgb(0, 0, 0),
        });
      });

      const pdfBytesVar = await pdfDocVar.save();

      console.log(pdfBytesVar);

      let binaryVar = "";

      for (let i = 0; i < pdfBytesVar.length; i++) {
        binaryVar += String.fromCharCode(pdfBytesVar[i]);
      }
      binaryVar = btoa(binaryVar);

      console.log(binaryVar);

      let pdfVar = await b64toBlob(binaryVar, "application/pdf");

      // let content_pdfVar = `data:application/pdf;base64,${binaryVar}`;

      if (downloadMode) {
        const pdfUrl = URL.createObjectURL(pdfVar);
        const link = document.createElement("a");
        link.href = pdfUrl;
        link.download = "Letter.pdf"; // specify the filename
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } else {
        await window.open(URL.createObjectURL(pdfVar));
      }
    }
  };

  const copyPage = (originalPage) => {
    const cloneNode = originalPage.node.clone();

    const { Contents } = originalPage.node.normalizedEntries();
    if (Contents) cloneNode.set(PDFName.of("Contents"), Contents.clone());

    const cloneRef = originalPage.doc.context.register(cloneNode);
    const clonePage = PDFPage.of(cloneNode, cloneRef, originalPage.doc);
    return clonePage;
  };

  //preview file
  const previewAttachment = (obj = {}) => {
    console.log(obj);
    obj.loadType = "Preview File";
    obj.size = "xl";
    setPayload({ ...obj });
    handleShow();
  };

  if (loader) return <Loader />;
  // if (showSingleMailDetails.isShown)
  //   return (
  //     <EmailDetail
  //       emailPayload={showSingleMailDetails.emailPayload}
  //       setShowSingleMailDetails={setShowSingleMailDetails}
  //       callGivenOption={callGivenOption}
  //       printPages={printPages}
  //       userData={userData}
  //       hostname={hostname}
  //       jwtToken={jwtToken}
  //     />
  //   );

  return (
    <div>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="">
              <Link className="text-secondary" to={"/"}>
                Home
              </Link>
              {" / "}
              <Link to={"#!"}>Inbox</Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>
        <Card.Body className="" style={{ minHeight: "95vh" }}>
          <Flex justifyContent={"between"} alignItems={"start"} className="">
            <h4>Inbox</h4>
            {/* <Button variant="primary" size="sm" onClick={handleShow} /> */}
            {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
            {/* <Button variant="primary" size="sm" onClick={handleShow}>
                Add New Parking
              </Button> */}
          </Flex>
          {/* <Row>
              <Col md="6">
                <p>long long text for property management</p>
              </Col>
            </Row> */}
          <Row className="flex-end-center mb-1">
            <Col className=" d-flex justify-content-sm-end">
              {bulkMails.length > 0 && (
                <div className="me-3 hide-pdf-div">
                  <Button
                    variant="falcon-success"
                    onClick={() => applyBulkAction("download")}
                    disabled={bulkMails.length < 1}
                  >
                    <Download />
                  </Button>

                  <Button
                    variant="falcon-danger"
                    onClick={() => applyBulkAction("delete")}
                    disabled={bulkMails.length < 1}
                    className="ms-3"
                  >
                    <Delete />
                  </Button>
                </div>
              )}
              <div className="me-3">
                <Button variant="primary" type="button" onClick={refreshTable}>
                  Refresh <RefreshIcon />
                </Button>
              </div>

              <CustomSearchbar
                searchFlags={[
                  "sender_name",
                  "title",
                  "descriptionFull",
                  "time",
                  "timeFull",
                ]}
                dataListTemp={emailsListTemp}
                stateUpdator={setEmailsList}
                placeholders={["sender", "subject", "description", "date"]}
              />
            </Col>
          </Row>

          <InboxTable
            list={emailsList}
            displaySingleEmailData={displaySingleEmailData}
            bulkMails={bulkMails}
            addBulkAction={addBulkAction}
            previewAttachment={previewAttachment}
          />
          <br />
          <InboxModal show={show} handleClose={handleClose} payload={payload} />
        </Card.Body>
      </Card>
    </div>
  );
}
