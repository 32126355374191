// import FalconCardHeader from 'components/common/FalconCardHeader';
import React, { useContext } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import csc from "countries-states-cities";
// import Cookies from 'universal-cookie';
import axios from "axios";
import { AuthWizardContext } from "../../../../context/Context";

import { Formik, Field, ErrorMessage } from "formik";

import * as Yup from "yup";
import { getHostName } from "../../../../helpers/utils";

const Address = () => {
  // const cookies = new Cookies();
  // const userData = cookies.get('userData');

  const { step, setStep, user, setUser } = useContext(AuthWizardContext);
  console.log(user);

  let hostname = getHostName();
  
  const handleSubmit = (values) => {
    console.log(values);

    let addressData = {};

    addressData.country_id = values.country;
    addressData.state_id = values.state;
    addressData.city_id = values.city;

    addressData.country = csc.getCountryById(+values.country).name;
    addressData.state = csc.getStateById(+values.state).name;
    addressData.city = csc.getCityById(+values.city).name;

    addressData.address = values.address;
    addressData.address2 = values.address2;
    addressData.zipcode = values.zipcode;

    console.log(addressData);
    let allData = { ...user, ...addressData };
    // allData.firstName = allData.first_name;
    // allData.lastName = allData.last_name;
    console.log(allData);
    // return;
    axios
      .post(`${hostname}/api/auth/companyreg`, { data: { ...allData } })
      .then((res) => {
        console.log(res.data);
        // setUser({ ...addressData, ...user });
        setUser(Object.assign({}, user, addressData));

        toast.success("Account Created", { theme: "colored" });
        setStep(step + 1);
      })
      .catch((err) => {
        console.log(err);
        toast.error(err.response.data.error || "Something went wrong!", {
          theme: "colored",
        });
      });
  };

  const handleStepMinus = (values) => {
    setUser(Object.assign({}, user, values));

    setStep(step - 1);
  };

  const handleSetCountry = (e, setFieldValue) => {
    const { value } = e.target;

    setFieldValue("country", +value);
    setFieldValue("city", "");
  };

  return (
    <div className="d-flex justify-content-center">
      <div className="">
        <Formik
          initialValues={{
            country: user.country || "",
            state: user.state || "",
            city: user.city || "",
            zipcode: user.zipcode || "",
            address: user.address || "",
            address2: user.address2 || "",
          }}
          validationSchema={Yup.object({
            country: Yup.string().required("country is required!"),
            state: Yup.string().required("state is required!"),
            city: Yup.string().required("city is required!"),
            zipcode: Yup.string().required("zipcode is required!"),
            address: Yup.string().required("address is required!"),
            address2: Yup.string("address2 is text!"),
          })}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(false);
            handleSubmit(values);
          }}
        >
          {({
            values,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="country">Country</label>
                  <Field
                    component="select"
                    id="countryProperty"
                    name="country"
                    className="form-select"
                    onChange={(e) => handleSetCountry(e, setFieldValue)}
                  >
                    <option>select</option>
                    {csc.getAllCountries()?.map((v, i) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="country"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                <Col md={12}>
                  <label htmlFor="state">State/Province</label>
                  <Field
                    component="select"
                    id="stateProperty"
                    name="state"
                    className="form-select"
                    // multiple={true}
                  >
                    <option value="">select</option>
                    {csc.getStatesOfCountry(+values.country)?.map((v, i) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="state"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="city">City</label>
                  <Field
                    component="select"
                    id="cityProperty"
                    name="city"
                    className="form-select"
                    // multiple={true}
                  >
                    <option value="">select</option>
                    {csc.getCitiesOfState(+values.state)?.map((v, i) => (
                      <option key={v.id} value={v.id}>
                        {v.name}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage
                    name="city"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                <Col md={12}>
                  <label htmlFor="zipcode">Zip/Postal Code</label>
                  <Field
                    name="zipcode"
                    className="form-control"
                    placeholder="enter zipcode"
                  />
                  <ErrorMessage
                    name="zipcode"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} className="mb-3">
                  <label htmlFor="address">Address</label>
                  <Field
                    name="address"
                    className="form-control"
                    placeholder="enter address"
                  />
                  <ErrorMessage
                    name="address"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
                <Col md={12} className="mb-3">
                  <label htmlFor="address2">Address 2 (if any)</label>
                  <Field
                    name="address2"
                    className="form-control"
                    placeholder="enter 2nd address here"
                  />
                  <ErrorMessage
                    name="address2"
                    className="text-danger fs--1"
                    component={"div"}
                  />
                </Col>
              </Row>
              <div className="">
                <Button
                  variant="secondary"
                  type="button"
                  onClick={() => handleStepMinus(values)}
                >
                  Previous
                </Button>
                <Button
                  variant="primary"
                  className="float-end"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Finish
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

// const Address = () => {
//   try {
//     const { step, setStep, user, setUser } = useContext(AuthWizardContext);
//     console.log(user);

//     const addressFromik = useFormik({
//       initialValues: {
//         country: 'India',
//         state: 'mumbai',
//         city: null
//       }
//       // onSubmit: values => console.log(JSON.stringify(values))
//     });
//     const cookies = new Cookies();
//     // const user_id = cookies.get('xyz');
//     const userData = cookies.get('userData');
//     // console.log(userData);
//     const [formData, setFormData] = useState({
//       zipcode: '',
//       address: '',
//       address2: '',
//       country: ''
//     });

//     // const [formData2, setFormData2] = useState({
//     //   countryid: '',
//     //   stateId: '',
//     //     });

//     // const { countryId,stateId} = formData;
//     const countries = csc.getAllCountries();

//     const countryvalue = formData.country;

//     const updatedCountries = countries.map(country => ({
//       label: country.name,
//       value: country.id,
//       ...country
//     }));

//     const updatedStates = countryId =>
//       csc
//         .getStatesOfCountry(countryId)
//         .map(state => ({ label: state.name, value: state.id, ...state }));

//     const updatedCities = stateId =>
//       csc
//         .getCitiesOfState(stateId)
//         .map(city => ({ label: city.name, value: city.id, ...city }));

//     const { values, setFieldValue, setValues } = addressFromik;

//     useEffect(() => {}, [values]);

//     // useEffect(async () => {

//     //   const config = {
//     //     headers: {
//     //       'Content-Type': 'application/json'
//     //     }
//     //   };
//     //   try {
//     //     const info = await axios.post(
//     //       `http://localhost:5000/api/auth/showaddress`,
//     //       {
//     //         user_id
//     //       },
//     //       config
//     //     );

//     //     console.log(info.data.user);

//     //     setFormData({
//     //       zipcode: info.data.user.zipcode,
//     //       address: info.data.user.address,
//     //       address2: info.data.user.address2
//     //     });

//     //     const Contrynfo = {
//     //       label: info.data.user.country,
//     //       id: info.data.user.country_id
//     //     };
//     //     const cityinfo = {
//     //       label: info.data.user.city,
//     //       id: info.data.user.city_id
//     //     };
//     //     const stateinfo = {
//     //       label: info.data.user.state,
//     //       id: info.data.user.state_id
//     //     };

//     //     values.country = Contrynfo;
//     //     values.city = cityinfo;
//     //     values.state = stateinfo;
//     //     console.log(values);

//     //     setValues({ country: Contrynfo, state: stateinfo, city: cityinfo }, true);
//     //   } catch (error) {
//     //     console.log(error);
//     //   }
//     // }, []);
//     const handleChange = e => {
//       setFormData({
//         ...formData,
//         [e.target.name]: e.target.value
//       });
//     };

//     const handleSubmit = async e => {
//       e.preventDefault();
//       // console.log(values);
//       try {
//         var country_id;
//         var country;
//         try {
//           country_id = values.country.id;
//           country = values.country.label;
//         } catch {
//           country_id = values.city.country_id;
//           country = countryvalue.label;
//         }

//         const state_id = values.state.id;

//         const state = values.state.label;
//         const city_id = values.city.id;
//         const city = values.city.label;
//         const zipcode = formData.zipcode;
//         const address = formData.address;
//         const address2 = formData.address2 || 'none';

//         // console.log(
//         //   user_id,
//         //   country,
//         //   country_id,
//         //   state_id,
//         //   state,
//         //   city_id,
//         //   city,
//         //   zipcode,
//         //   address,
//         //   address2
//         // );
//         setUser(() =>
//           Object.assign(
//             {
//               country,
//               country_id,
//               state_id,
//               state,
//               city_id,
//               city,
//               zipcode,
//               address,
//               address2,
//               user_id: userData.data,
//               c_id: userData.compid
//             },
//             user
//           )
//         );
//         let alldata = {
//           ...user,
//           country,
//           country_id,
//           state_id,
//           state,
//           city_id,
//           city,
//           zipcode,
//           address,
//           address2,
//           user_id: userData.data,
//           c_id: userData.compid
//         };
//         // console.log(alldata);
//         axios
//           .post('http://localhost:5000/api/property/propertyregister', {
//             ...alldata
//           })
//           .then(res => {
//             // console.log(res.data);
//             toast.success(res.data.data, { theme: 'colored' });
//             setStep(step + 1);
//             setFormData({
//               zipcode: '',
//               address: '',
//               address2: '',
//               country: ''
//             });
//           })
//           .catch(err => {
//             console.log(err);
//             toast.error(err.response.data.error, { theme: 'colored' });
//           });

//         //   const config = {
//         //     headers: {
//         //       'Content-Type': 'application/json'
//         //     }
//         //   };
//         //   try {
//         //     const { data } = await axios.post(
//         //       `http://localhost:5000/api/auth/Address`,
//         //       {
//         //         user_id,
//         //         country_id,
//         //         country,
//         //         state_id,
//         //         state,
//         //         city_id,
//         //         city,
//         //         zipcode,
//         //         address,
//         //         address2
//         //       },
//         //       config
//         //     );
//         //     console.log(data);
//         //     toast.success('Update successfull', {
//         //       theme: 'colored'
//         //     });
//         //   } catch (error) {
//         //     toast.error('Something went wrong', {
//         //       theme: 'colored'
//         //     });
//         //   }
//       } catch (error) {
//         toast.error('Please fill all required fields to continue!', {
//           theme: 'colored'
//         });
//       }
//     };

//     return (
//       <Form onSubmit={handleSubmit}>
//         <Row>
//           <Col sm={12} className='mb-3'>
//             <Form.Group className="mb-3" controlId="countryProperty">
//               <Form.Label>Country</Form.Label>
//               <Select
//                 id="country"
//                 name="country"
//                 tabIndex="1"
//                 label="country"
//                 options={updatedCountries}
//                 value={values.country}
//                 onChange={value => {
//                   formData.country = value;
//                   setValues({ country: value, state: null, city: null }, false);
//                 }}
//               />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="cityProperty">
//               <Form.Label>City</Form.Label>

//               <Select
//                 id="city"
//                 name="city"
//                 tabIndex="3"
//                 options={updatedCities(
//                   values.state ? values.state.value : null
//                 )}
//                 value={values.city}
//                 onChange={value => {
//                   setFieldValue('city', value);
//                 }}
//               />
//             </Form.Group>
//           </Col>
//           <Col sm={12} className='mb-3'>
//             <Form.Group className="mb-3" controlId="stateProperty">
//               <Form.Label>State/Province</Form.Label>
//               <Select
//                 id="state"
//                 name="state"
//                 tabIndex="2"
//                 options={updatedStates(
//                   values.country ? values.country.value : null
//                 )}
//                 value={values.state}
//                 onChange={value => {
//                   setValues({ state: value, city: null }, false);
//                 }}
//               />
//             </Form.Group>
//             <Form.Group className="mb-3" controlId="zipcodeProperty">
//               <Form.Label>Zip/Postal Code</Form.Label>
//               <div className="position-relative">
//                 <Form.Control
//                   // className='position-relative'
//                   type="text"
//                   // value={formData.confirmpassword}
//                   tabIndex="4"
//                   name="zipcode"
//                   value={formData.zipcode}
//                   onChange={handleChange}
//                   placeholder="Enter your code"
//                 />
//                 <span
//                   className="position-absolute top-50 end-0 translate-middle"
//                   style={{ color: 'silver' }}
//                 >
//                   <span className="me-1">|</span>
//                   <ExpandMoreIcon className="" />
//                 </span>
//               </div>
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <Form.Group className="mb-3" controlId="adderssProperty">
//               <Form.Label>Address 1</Form.Label>
//               <Form.Control
//                 as={'textarea'}
//                 rows={1}
//                 type="text"
//                 tabIndex="5"
//                 name="address"
//                 value={formData.address}
//                 onChange={handleChange}
//                 placeholder="Enter residential address"
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <Form.Group className="mb-3" controlId="address2Property">
//               <Form.Label>Address 2</Form.Label>
//               <Form.Control
//                 as={'textarea'}
//                 rows={1}
//                 type="text"
//                 // value={formData.confirmpassword}
//                 tabIndex="12" className='mb-3'
//                 name="address2"
//                 onChange={handleChange}
//                 value={formData.address2}
//                 placeholder="Enter 2nd Address (if any)"
//               />
//             </Form.Group>
//           </Col>
//         </Row>
//         <br />
//         {/* <div className="text-end">
//         <Button variant="primary" type="submit">
//           Next <FontAwesomeIcon icon="chevron-right" size="sm" />
//         </Button>
//       </div> */}
//         <div className="d-flex justify-content-between">
//           <Button
//             variant="primary-outline"
//             className="float-end"
//             // type="submit"
//             // disabled={step}
//             onClick={() => setStep(step - 1)}
//           >
//             Prev
//           </Button>
//           <Button
//             variant="primary"
//             className="float-end"
//             type="submit"
//             // disabled={isSubmitting}
//           >
//             Next <FontAwesomeIcon icon="chevron-right" size="sm" />
//           </Button>
//         </div>
//       </Form>
//     );
//   } catch (e) {
//     console.log(e);
//     return <p className="text-danger">{e.message}</p>;
//   }
// };

export default Address;
