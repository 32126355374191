import { AuthWizardContext } from "context/Context";
import React from "react";
import Lottie from "lottie-react";
import PropTypes from "prop-types";
import { Button, Col, Row } from "react-bootstrap";
import celebration from "assets/img/animated-icons/celebration.json";
// import Cookies from "universal-cookie";
import { useNavigate, useParams } from "react-router-dom";

const Success = ({
  // reset,
  selectedAmenity,
  user,
  handleClose,
  getAmenitiesData,
}) => {
  const { setStep, setUser } = React.useContext(AuthWizardContext);

  const navigate = useNavigate();

  const { unit_id, unit_number } = useParams();

  const emptyData = (link) => {
    setStep(1);
    setUser({});
    // reset();
    handleClose();
    getAmenitiesData();
    if (link === "booking") navigate("/owner-portal/my-bookings");
    if (link === "invoice")
      navigate(`/owner-portal/my-invoices/${unit_id}/${unit_number}`);
  };

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">
            Booking for{" "}
            <span className="text-success">{selectedAmenity.amenityname}</span>{" "}
            is successfully completed
          </h4>
          <p className="fs-0"></p>
          <Button
            color="success"
            className="px-5 my-3"
            onClick={() => emptyData("invoice")}
          >
            Go to Invoices List
          </Button>
          <br />
          <Button
            color="primary"
            className="px-5 my-3"
            onClick={() => emptyData("booking")}
          >
            Go to Booking List
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired,
};

export default Success;
