import { chartJsDefaultTooltip } from '../../../../helpers/chartjs-utils';
import { getColor } from '../../../../helpers/utils';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import {Chart, ArcElement} from 'chart.js'
Chart.register(ArcElement);

const ProductShareChart = ({reqRate}) => {
  const options = {
    tooltip: chartJsDefaultTooltip(),
    rotation: -90,
    circumference: '180',
    cutout: '80%',
    plugins: {
      legend: {
        display: false
      },
      tooltip: chartJsDefaultTooltip()
    }
  };
  const data = {
    labels: ['Open', 'Closed'],
    datasets: [
      {
        data: [reqRate.rate,100 - reqRate.rate],
        backgroundColor: [getColor('primary'), getColor('gray-300')],
        borderColor: [getColor('primary'), getColor('gray-300')]
      }
    ]
  };
  return <Doughnut data={data} options={options} width="112" />;
};

export default ProductShareChart;
