import React, { useContext, useEffect, useState } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import classNames from 'classnames';
import AppContext from '../../../context/Context';
// import Logo from 'components/common/Logo';
import SearchBox from './SearchBox';
// import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../../config';
import autoCompleteInitialItem from '../../../data/autocomplete/autocomplete';
import TopNavRightSideNavItem from './TopNavRightSideNavItem';
import { useLocation } from 'react-router';

const NavbarTop = props => {
  
  const {
    config: {
      showBurgerMenu,
      navbarPosition,
      navbarCollapsed,
      // isNavbarVerticalCollapsed
    },
    setConfig
  } = useContext(AppContext);

  console.log(navbarPosition);
  

  const { pathname } = useLocation();
  const isChat = pathname.includes('chat');
  // console.log(isNavbarVerticalCollapsed);
  const [showDropShadow, setShowDropShadow] = useState(false);

  const handleBurgerMenu = () => {
    navbarPosition === 'top' && setConfig('navbarCollapsed', !navbarCollapsed);
    (navbarPosition === 'vertical' || navbarPosition === 'combo') &&
      setConfig('showBurgerMenu', !showBurgerMenu);
  };

  const setDropShadow = () => {
    const el = document.documentElement;
    if (el.scrollTop > 0) {
      setShowDropShadow(true);
    } else {
      setShowDropShadow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', setDropShadow);
    return () => window.removeEventListener('scroll', setDropShadow);
  }, []);

 

  return (
    <Navbar
      className={classNames(
        'navbar-glass flex-grow-1  fs--1 navbar-top sticky-kit',
        {
          // 'navbar-glass-shadow': showDropShadow
          'navbar-glass-shadow': showDropShadow && !isChat
        }
      )}
      expand={
        navbarPosition === 'top' || navbarPosition === 'combo'
          ? topNavbarBreakpoint
          : true
      }
    >
      <Navbar.Toggle
        className={classNames('toggle-icon-wrapper me-md-3 me-2', {
          'd-lg-none': navbarPosition === 'top',
          [`d-${navbarBreakPoint}-none`]:
            navbarPosition === 'vertical' || navbarPosition === 'combo'
        })}
        as="div"
      >
        <button
          className="navbar-toggler-humburger-icon btn btn-link d-flex flex-center"
          onClick={handleBurgerMenu}
          id="burgerMenu"
        >
          <span className="navbar-toggle-icon">
            <span className="toggle-line" />
          </span>
        </button>
      </Navbar.Toggle>
      
        <Nav
          navbar
          className={`align-items-center d-none d-${topNavbarBreakpoint}-block`}
          as="ul"
        >
          <Nav.Item as="li">
            <SearchBox autoCompleteItem={autoCompleteInitialItem} />
          </Nav.Item>
        </Nav>
       
      
      
        {/* <span className=' ms-3 me-3'> {localStorage.getItem('Windows') || ''}</span> */}
      {/* <span className="ms-3 text-uppercase d-flex ">
        <span className='me-3'>Buisness type : </span>
        <Nav.Item className='text-primary'>
          {userData.user_type === 'property_portal' && 'Company Portal'}
        </Nav.Item>
        <Nav.Item className='text-primary'>{userData.user_type === 'reg_company' && 'Company'}</Nav.Item>
        <Nav.Item>
          {userData.user_type === 'owner_portal' && 'Owner Portal'}
        </Nav.Item>
        <Nav.Item className='text-primary'>{userData.user_type === 'reg_owner' && 'Owner'}</Nav.Item>
      </span> */}
      
      <TopNavRightSideNavItem userData={props.userData} avatar={props.avatar} profileDropdownData={props.profileDropdownData}/>
    </Navbar>
  );
};


export default NavbarTop;
