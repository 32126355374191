import React from "react";
import { useState } from "react";

import Flex from "../../../../../components/common/Flex";
import { Button, Card, Col, Dropdown, Row } from "react-bootstrap";

import axios from "axios";
import { useParams, Link } from "react-router-dom";

import PaginateComponentCustom from "../../../../../components/common/PaginateComponentCustom";
import UnitInfoTable from "./UnitInfoTable";
import { capitalize, startCase } from "lodash";
import { RefreshOutlined } from "@mui/icons-material";
import Loader from "../../../../../components/common/Loader";
import UnitsManagementModel from "../../../sharedUI/UnitsManagementModel";
import CustomFilterbar from "../../../../../components/common/CustomFilterbar";
import CustomSearchbar from "../../../../../components/common/CustomSearchbar";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "../../../../../helpers/utils";

const UnitInfo = () => {
  let hostname = getHostName();

  const cookies = new Cookies();

  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name } = useParams();

  const [headerData, setHeaderData] = useState({
    units: 0,
    empty_units: 0,
    parkingsCount: 0,
    lockersCount: 0,
    cef: 0,
  });

  const [unitsData, setUnitsData] = React.useState([]);
  const [unitsDataTemp, setUnitsDataTemp] = React.useState([...unitsData]);

  const [loader, setLoader] = useState(false);

  const [showModel, setShowModel] = React.useState(false);

  const [payload, setPayload] = React.useState({});

  const handleShowModel = () => setShowModel(true);
  const handleCloseModel = () => setShowModel(false);

  const addUnit = () => {
    let obj = {};
    obj.property_id = property_id;
    obj.loadType = "Add Unit";
    setPayload({ ...obj });
    handleShowModel();
  };

  const updateUnit = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Update Unit";
    setPayload({ ...unit });
    handleShowModel();
  };

  const addUnitOwnerNew = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Add Unit Owner";
    setPayload({ ...unit });
    handleShowModel();
  };

  const changeUnitOwnerNew = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Change Unit Owner";
    setPayload({ ...unit });
    handleShowModel();
  };

  const reinviteUnitOwnerNew = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Reinvite Unit Owner";
    setPayload({ ...unit });
    handleShowModel();
  };

  const attachUnitWithCondoUnit = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Attach Unit";
    setPayload({ ...unit });
    handleShowModel();
  };

  const detachUnitFromCondoUnit = (unit) => {
    unit.property_id = property_id;
    unit.loadType = "Detach Unit";
    setPayload({ ...unit });
    handleShowModel();
  };

  React.useEffect(() => {
    setLoader(true);
    getUnitsData();
  }, []);

  const refreshTable = () => {
    setLoader(true);
    getUnitsData();
  };

  const getUnitsData = () => {
    let p_id = property_id;
    if (p_id) {
      axios
        .post(
          `${hostname}/api/property/showunits`,
          {
            p_id,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          let unitBaseDetails = res.data.unitBaseDetails;
          let arrU = res.data.data.map((u) => {
            let userinfo = {};
            // if (u.userinfo !== undefined) {
            if (Object.hasOwnProperty.call(u, userinfo)) {
              // console.log(u.userinfo);
              userinfo = u.userinfo;
              delete u.userinfo;
            }
            let obj = Object.assign(
              { p_id: property_id, p_name: property_name },
              { ...userinfo },
              u
            );
            return obj;
          });
          console.log(arrU);
          arrU = arrU.map((v) => {
            let obj = {};
            // console.log(v.unit_type);
            // obj.unit_type = capitalize(v.unit_type);
            if (
              // Object.hasOwnProperty.call(v, "userinfo") === false &&
              // Object.hasOwnProperty.call(v, "Inviteduser") === false
              v.resident_status === "Not Invited"
            ) {
              obj = Object.assign(
                {
                  status: {
                    title: "Pending",
                    type: "warning",
                    icon: "check",
                  },
                  type: "no-owner",
                },
                v
              );
            }
            if (
              // Object.hasOwnProperty.call(v, "userinfo") === false &&
              // Object.hasOwnProperty.call(v, "Inviteduser") === true
              v.resident_status === "Invited"
            ) {
              obj = Object.assign(
                {
                  status: {
                    title: "Invited",
                    type: "info",
                    icon: "check",
                  },
                  type: "invited-owner",
                },
                v
              );
            }
            if (
              // Object.hasOwnProperty.call(v, "userinfo") === true &&
              // Object.hasOwnProperty.call(v, "Inviteduser") === false
              v.resident_status === "Ignored"
            ) {
              obj = Object.assign(
                {
                  status: {
                    title: "Ignored",
                    type: "secondary",
                    icon: "redo",
                  },
                  type: "no-owner",
                },
                v
              );
            }
            if (
              // Object.hasOwnProperty.call(v, "userinfo") === true &&
              // Object.hasOwnProperty.call(v, "Inviteduser") === false
              v.resident_status === "Active"
            ) {
              obj = Object.assign(
                {
                  status: {
                    title: "Active",
                    type: "success",
                    icon: "check",
                  },
                  type: "linked-owner",
                },
                v
              );
            }
            return obj;
          });
          // arrU = arrU.map(v=>{
          //   let obj = {...v};
          //   if(obj.unit_type === 'condo')
          //     obj.unit_type = 'Condo';
          //   return obj;
          // })
          console.log(arrU);

          // arrU = arrU?.sort((a, b) => (a.unit_number > b.unit_number ? 1 : -1));

          arrU = arrU.sort((a, b) =>
            +a.unit_number > +b.unit_number
              ? 1
              : +b.unit_number > +a.unit_number
              ? -1
              : 0
          );

          setUnitsData(arrU);
          setUnitsDataTemp(arrU);

          setHeaderData({
            units: unitBaseDetails.Total_Units,
            empty_units: unitBaseDetails.Empty_Units,
            cef: unitBaseDetails.Total_Cef,
            lockersCount: unitBaseDetails.Lockers,
            parkingsCount: unitBaseDetails.Parking_Spots,
          });

          // let t_units = arrU.length;
          // let t_cef = 0.0;
          // arrU?.forEach((cef) => {
          //   if (Number.isInteger(+cef.cef)) t_cef += +cef.cef;
          // });
          // let empty_units = arrU.filter((v) => {
          //   if (!Object.hasOwnProperty.call(v, "attachedToUnit")) {
          //     return v.userinfo === undefined && v.Inviteduser === undefined;
          //   }
          //   return v.userinfo === undefined && v.Inviteduser === undefined;
          // }).length;
          // let lockersCount = arrU.filter((v) => {
          //   return v.unit_type === "Locker";
          // }).length;
          // let parkingsCount = arrU.filter((v) => {
          //   return v.unit_type === "Parking Spot";
          // }).length;

          // setHeaderData({
          //   units: t_units,
          //   empty_units,
          //   cef: t_cef?.toFixed(0),
          //   lockersCount,
          //   parkingsCount,
          // });

          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  const startUnitUploadFile = () => {
    let obj = {};
    obj.property_id = property_id;
    obj.loadType = "Add Bulk Units";
    setPayload({ ...obj });
    handleShowModel();
  };

  if (loader) return <Loader />;

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="d-flex align-items-center overflow-scroll scrollbar">
              <Link className="me-2 text-secondary" to="/dashboard">
                Home
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to="/property/management/buildings"
              >
                Properties
              </Link>
              {"/"}
              <Link
                className="me-2 ms-2 text-secondary"
                to={`/property/management/buildings/${property_id}/${property_name}/profile`}
              >
                {startCase(property_name)}
              </Link>
              {"/"}
              <Link className="me-2 ms-2 ">Units</Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>
        <Card.Body className=" pt-5" style={{ minHeight: "90vh" }}>
          <Flex
            justifyContent={"between"}
            alignItems={"start"}
            className="mb-3"
          >
            <h4>Units List</h4>
            {/* <Button variant="primary" size="sm" onClick={handleShow} /> */}
            {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
            <div className="d-flex">
              <Button
                variant="light"
                size="sm"
                onClick={refreshTable}
                className="me-3"
              >
                Refresh <RefreshOutlined />
              </Button>

              {(userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users") &&
                headerData.cef >= 100 && (
                  <Button variant="primary" size="sm">
                    Units Full!
                  </Button>
                )}
              {(userData.role === "Site-Administrators" ||
                userData.role === "Condozak-Basic-Users") &&
                headerData.cef < 100 && (
                  <Dropdown>
                    <Dropdown.Toggle size="sm">+ New </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={addUnit} className="text-center">
                        Add Unit
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={startUnitUploadFile}
                        className="text-center"
                      >
                        Upload Bulk Units
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                )}
            </div>
          </Flex>

          <Row style={{ width: "70%" }} className="mb-3">
            <Col>
              <div className="d-flex flex-column align-items-center">
                <h6 className="fw-bold">Total Condos</h6>
                <h5 className="text-primary">{headerData.units || "0"}</h5>
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-column align-items-center">
                <h6 className="fw-bold">Parkings</h6>
                <h5 className="text-primary">
                  {headerData.parkingsCount || "0"}
                </h5>
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-column align-items-center">
                <h6 className="fw-bold">Lockers</h6>
                <h5 className="text-primary">
                  {headerData.lockersCount || "0"}
                </h5>
              </div>
            </Col>
            <Col>
              <div className="d-flex flex-column align-items-center">
                <h6 className="fw-bold">Total %CEF</h6>
                <h5 className="text-primary">{headerData.cef || "0"}%</h5>
              </div>
            </Col>
            <Col className="d-flex justify-content-between">
              <div className="d-flex flex-column align-items-center">
                <h6 className="fw-bold">Empty Condos</h6>
                <h5 className="text-primary">
                  {headerData.empty_units || "0"}
                </h5>
              </div>
            </Col>
          </Row>
          <Row className="flex-end-center mb-3 mt-3">
            <Col className=" d-flex justify-content-sm-end">
              <CustomFilterbar
                filterFlags={[
                  "Status Active",
                  "Status Pending",
                  "Status Invited",
                  "Type Condo",
                  "Type Non-Condo",
                ]}
                dataListTemp={unitsDataTemp}
                stateUpdator={setUnitsData}
                from="Units Table Manager"
              />

              <CustomSearchbar
                searchFlags={[
                  "unit_number",
                  "unit_type",
                  "cef",
                  "Inviteduser",
                  "userinfo.email",
                  "userinfo.firstname",
                  "userinfo.lastname",
                ]}
                dataListTemp={unitsDataTemp}
                stateUpdator={setUnitsData}
                placeholders={[
                  "unit_number",
                  "unit_type",
                  "Inviteduser",
                  "email",
                ]}
              />
            </Col>
          </Row>

          <PaginateComponentCustom
            itemsPerPage={20}
            dataList={unitsData}
            TableElement={UnitInfoTable}
            updateUnit={updateUnit}
            addUnitOwnerNew={addUnitOwnerNew}
            changeUnitOwnerNew={changeUnitOwnerNew}
            reinviteUnitOwnerNew={reinviteUnitOwnerNew}
            attachUnitWithCondoUnit={attachUnitWithCondoUnit}
            detachUnitFromCondoUnit={detachUnitFromCondoUnit}
            userData={userData}
          />

          <br />

          <UnitsManagementModel
            show={showModel}
            handleClose={handleCloseModel}
            payload={payload}
            setPayload={setPayload}
            userData={userData}
            getAllData={getUnitsData}
            unitsData={unitsData}
            headerData={headerData}
            hostname={hostname}
            jwtToken={jwtToken}
            property_id={property_id}
          />
        </Card.Body>
      </Card>
    </>
  );
};

export default UnitInfo;
