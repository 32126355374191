import React from "react";
// import { Card, Col, Row, Form } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import Section from "../components/common/Section";
import { Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";

import LogoBottom from "../components/common/LogoBottom";

const AuthSimpleLayout = () => {
  const [languageOfApp, setLanguageOfApp] = React.useState(
    window.localStorage.getItem("language") || "en"
  );

  const { i18n } = useTranslation();
  const changeAppLanguage = (e) => {
    window.localStorage.setItem("language", e.target.value);
    setLanguageOfApp(e.target.value);
    i18n.changeLanguage(e.target.value);
  };


  return (
    <Section className="py-0 position-relative">
      <Row className="flex-center min-vh-100 py-0">
        <Col xs={10} sm={10} md={8} lg={6} xl={5} className="col-xxl-4">
          <div className=" d-flex justify-content-center align-items-center">
            <LogoBottom width={"300px"} />
          </div>

          <br />

          <Card>
            <Card.Body className="p-3 p-sm-5">
              <Outlet />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <span className="position-absolute top-0 end-0 p-3">
        <Form.Select
          size="sm"
          onChange={changeAppLanguage}
          value={languageOfApp}
        >
          <option value={"en"}>English</option>
          <option value={"fr"}>French</option>
        </Form.Select>
      </span>
    </Section>
  );
};

export default AuthSimpleLayout;
