import { AuthWizardContext } from "context/Context";
import Lottie from "lottie-react";
import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import celebration from "assets/img/animated-icons/celebration.json";

// const Success = ({ reset }) => {
const Success = ({ handleClose, getAllData }) => {
  const { user } = useContext(AuthWizardContext);

  return (
    <>
      <Row>
        <Col className="text-center">
          <div className="wizard-lottie-wrapper">
            <div className="wizard-lottie mx-auto">
              <Lottie animationData={celebration} loop={true} />
            </div>
          </div>
          <h4 className="mb-1">Success</h4>
          <p className="fs-0">Event created successfully</p>
          <Button
            variant="primary"
            className="px-5 my-3 btn btn-primary"
            onClick={handleClose}
          >
            Go to events list
          </Button>
        </Col>
      </Row>
    </>
  );
};

Success.propTypes = {
  reset: PropTypes.func.isRequired,
};

export default Success;
