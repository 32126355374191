import React, { useState } from "react";

import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";

import Cookies from "universal-cookie";

import { Link, useNavigate } from "react-router-dom";

import { toast } from "react-toastify";

import axios from "axios";

import { RotatingLines } from "react-loader-spinner";

import { useTranslation } from "react-i18next";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import jwtDecode from "jwt-decode";
import { getHostName } from "../../helpers/utils";

const LoginForm = ({ hasLabel }) => {
  const { t } = useTranslation();
  const hostname = getHostName();

  // State
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    remember: false,
  });

  const [showLoader, setLoader] = useState(false);
  const [psValue, setPsValue] = useState(false);
  const { email, password } = formData;

  const cookies = new Cookies();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoader(true);

    let link = "";

    if (window.location.pathname === "/property/login") {
      link = `${hostname}/api/auth/Ologin`;
    } else {
      link = `${hostname}/api/auth/login`;
    }

    axios
      .post(link, {
        email,
        password,
        siteName: window.location.hostname,
        port: window.location.port,
        remember: formData.remember,
      })
      .then((res) => {
        console.log(res.data);

        if (formData.remember) {
          cookies.set(
            "rememberMe",
            JSON.stringify({
              email: formData.email,
              base: window.location.hostname,
            })
          );
        } else {
          // console.log("clearing rememberMe");
          cookies.remove("rememberMe");
        }

        // set user
        cookies.set("_expire_t", res.data.token, { path: "/" });

        let userData = jwtDecode(res.data.token);

        if (userData.state === "Active" || userData.state === "Invited")
          toast.success(`Logged in as ${formData.email}`);

        console.log(userData);

        // company invited user
        if (userData.role === "No role Assigned") {
          cookies.set("_expire_l", "/login", { path: "/" });

          // window.location.href = "/user/notifications";
          if (res.data.invitesCount > 0)
            navigate("/user/notifications/company_invited_user");
          else navigate("/errors/401/not-invited/company_invited_login");
        }

        if (
          userData.role === "Site-Administrators" || // manager
          userData.role === "Condozak-Basic-Users" // company basic user
        ) {
          if (userData.state === "Active" || userData.state === "Invited") {
            cookies.set("_expire_l", "/login", { path: "/" });
          }

          if (userData.state === "Revoked") {
            toast.error("Not Authorized!", { theme: "colored" });
            navigate("/errors/401/not-invited/access_revoked_login");
            return;
          }

          // navigate("/dashboard");
          window.location.href = "/dashboard";
        }

        if (
          userData.role === "unit_owner" // owner
        ) {
          if (userData.state === "Active" || userData.state === "Invited") {
            cookies.set("_expire_l", "/property/login", { path: "/" });
          }

          if (userData.state === "Revoked") {
            toast.error("Not Authorized!", { theme: "colored" });
            navigate("/errors/401/not-invited/access_revoked_ologin");
            return;
          }

          // navigate(
          // `/dashboard/${userData.UnitsOwner[0]._id}/${userData.UnitsOwner[0].unit_number}`
          // );
          if (res.data.invitesCount > 0)
            navigate("/user/notifications/unit_owner_multi_invited");
          else {
            window.location.href = `/dashboard/${userData.UnitsOwner[0]._id}/${userData.UnitsOwner[0].unit_number}`;
          }
        }

        // invited owner
        if (userData.role === "none") {
          cookies.set("_expire_l", "/property/login", { path: "/" });

          // navigate("/dashboard");
          // window.location.href = "/user/notifications";
          // navigate("/user/notifications");

          if (res.data.invitesCount > 0)
            navigate("/user/notifications/property_invited_user");
          else navigate("/errors/401/not-invited/property_invited_login");
        }

        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);
        if ("response" in err ? err.response.status === 400 : false) {
          // console.log(err.response.data.error);

          cookies.set("email_not_auth", email);
          cookies.set("link_auth", err.response.data.error);
          toast.error("Not Authorized!", { theme: "colored" });
          navigate("/errors/401/not-authorized");
        } else console.log(err);

        if ("response" in err ? err.response.status === 401 : false) {
          toast.error(`${err.response.data.error}`, { theme: "colored" });
        } else console.log(err);
      });
  };

  const handleFieldChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  React.useEffect(() => {
    // const cookies = new Cookies();
    let rme = cookies.get("rememberMe");

    if (rme !== "undefined" && rme?.base === window.location.hostname) {
      setFormData({ ...formData, email: rme.email, remember: true });
    }
  }, []);

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t("login.email_address")}</Form.Label>}
        <Form.Control
          // placeholder={!hasLabel ? "Email Address" : ""}
          placeholder={!hasLabel ? t("login.email_address") : ""}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>
      {/* <Link to={'/secure'}>secure</Link> */}
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t("login.password")}</Form.Label>}
        <div className="d-flex align-items-center position-relative">
          <Form.Control
            // placeholder={!hasLabel ? "password" : ""}
            placeholder={!hasLabel ? t("login.password") : ""}
            value={formData.password}
            name="password"
            onChange={handleFieldChange}
            type={psValue ? "text" : "password"}
          />
          <span
            className="position-absolute end-0 mx-3 cursor-pointer"
            onClick={() => setPsValue(!psValue)}
          >
            {psValue ? <VisibilityOffIcon /> : <VisibilityIcon />}
          </span>
        </div>
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col className="">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  remember: e.target.checked,
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700 fs--1">
              {t("login.remember_me")}
            </Form.Check.Label>
          </Form.Check>
        </Col>

        <Col className="">
          <Link
            className="mb-0 btn btn-link btn-sm fs--1"
            to={"/forgot-password"}
            // onClick={() => (window.location.href = "/forgot-password")}
            // onClick={() => navigate("/forgot-password")}
          >
            {t("login.forgot_password")}
          </Link>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password || showLoader}
        >
          {showLoader ? (
            <RotatingLines
              strokeColor="white"
              strokeWidth="5"
              animationDuration="0.75"
              width="20"
              visible={true}
            />
          ) : (
            "Log in"
          )}
        </Button>
      </Form.Group>

      {/* <SocialAuthButtons/>
      {/* <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
        <Link className="btn btn-primary" to={`/authentication/card/register`}>
            Register
        </Link>
      </div> */}
      {/* <SocialAuthButtons/> */}
    </Form>
  );
};

export default LoginForm;
