import { useState, useEffect } from "react";

import Flex from "../../../../../components/common/Flex";
import React from "react";
import { Button, Card, Col, Row, Breadcrumb } from "react-bootstrap";
import BudgetsTable from "./BudgetsTable";
import BudgetModel from "./BudgetModal";

import axios from "axios";
// import Cookies from "universal-cookie";
import Loader from "../../../../../components/common/Loader";
import { useParams } from "react-router-dom";
// import { formatLogInfo } from "../../../../../helpers/utils";
import { Link } from "react-router-dom";
import CustomSearchbar from "../../../../../components/common/CustomSearchbar";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName ,formatLogInfo} from "helpers/utils";

const Budgets = () => {
  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { property_id, property_name } = useParams();

  const [budgetsData, setBudgetsData] = useState([]);
  const [budgetsDataTemp, setBudgetsDataTemp] = useState([...budgetsData]);
  const [budgetDates, setBudgetDates] = React.useState({});

  useEffect(() => {
    setLoader(true);
    getBudgetsData();
    getBudgetDates();
  }, []);

  //   building list

  const getBudgetDates = () => {
    axios
      .post(
        `${hostname}/api/property/GetDates`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);

        let obj = {};
        if (res.data.Start_Date != null && res.data.End_Date != null) {
          obj = {
            start_date: new Date(res.data.Start_Date),
            end_date: new Date(res.data.End_Date),
            start_date_utc: res.data.Start_Date,
            end_date_utc: res.data.End_Date,
          };
          setBudgetDates({ ...obj });
          // console.log(obj);
          return;
        }
        // console.log(obj);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const [loader, setLoader] = React.useState(false);

  const [payload, setPayload] = React.useState({});

  // create  modal
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const getBudgetsData = () => {
    axios
      .post(
        `${hostname}/api/property/ReadAllBudgets`,
        {
          p_id: property_id,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        // console.log(res.data);

        let arr = res.data.Data?.map((v) => {
          let obj = { ...v };
          obj.start_of_fiscal_year = formatLogInfo(
            obj.start_of_fiscal_year,
            true
          );
          obj.end_of_fiscal_year = formatLogInfo(obj.end_of_fiscal_year, true);
          // obj.total_budget = formatToCurrency(+obj.total_budget);
          return obj;
        });

        getBudgetDates();

        setBudgetsData(arr);
        setBudgetsDataTemp(arr);

        setTimeout(() => {
          setLoader(false);
        }, 600);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const createBudget = () => {
    let obj = {};
    obj.loadType = "Create Budget";
    obj.userData = userData;
    obj.p_id = property_id;
    setPayload({ ...obj });
    handleShow();
  };
  const updateBudget = (budget) => {
    budget.loadType = "Update Budget";
    budget.userData = userData;
    budget.p_id = property_id;
    setPayload({ ...budget });
    handleShow();
  };
  const deleteBudget = (budget) => {
    budget.loadType = "Delete Budget";
    budget.userData = userData;
    budget.p_id = property_id;
    setPayload({ ...budget });
    handleShow();
  };

  if (loader) {
    return <Loader />;
  } else {
    return (
      <div>
        <Row className="g-3 mb-3">
          <Col>
            <Card>
              <Card.Body className="">
                <Breadcrumb>
                  <Breadcrumb.Item>
                    <Link className="text-secondary" to={"/"}>
                      Home
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      className="text-secondary"
                      to={"/property/management/buildings"}
                    >
                      Properties
                    </Link>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>
                    <Link
                      className="text-secondary"
                      to={`/property/management/buildings/${property_id}/${property_name}/profile`}
                    >
                      {property_name}
                    </Link>
                  </Breadcrumb.Item>{" "}
                  <Breadcrumb.Item active>
                    <Link to={"#!"}>Budget</Link>
                  </Breadcrumb.Item>
                </Breadcrumb>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Card>
          <Card.Body
            className="overflow-hidden pt-5"
            style={{ minHeight: "90vh" }}
          >
            <Flex
              justifyContent={"between"}
              alignItems={"start"}
              className="mb-3"
            >
              <h4>Budget</h4>
              {/* <Button variant="primary" size="sm" onClick={handleShow} /> */}
              {/* <Link
              to="/property/management/buildingInfo"
              variant="primary"
              size="sm"
            >
              Details
            </Link> */}
              <Button variant="primary" size="sm" onClick={createBudget}>
                Add Budget
              </Button>
            </Flex>
            <Row className="flex-end-center mb-3 mt-3">
              <Col className=" d-flex justify-content-sm-end">
                <CustomSearchbar
                  searchFlags={[
                    "start_of_fiscal_year",
                    "end_of_fiscal_year",
                    "total_budget",
                  ]}
                  dataListTemp={budgetsDataTemp}
                  stateUpdator={setBudgetsData}
                  placeholders={["year", "budget"]}
                />
              </Col>
            </Row>

            <BudgetsTable
              budgets={budgetsData}
              // selectBuilding={selectBuilding}
              updateBudget={updateBudget}
              deleteBudget={deleteBudget}
            />
            <br />

            <BudgetModel
              show={show}
              handleClose={handleClose}
              payload={payload}
              budgetDates={budgetDates}
              setPayload={setPayload}
              getAllData={getBudgetsData}
              
              hostname={hostname}
              jwtToken={jwtToken}
            />
          </Card.Body>
        </Card>
      </div>
    );
  }
};

export default Budgets;
