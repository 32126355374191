import { useState } from "react";

import Flex from "components/common/Flex";
import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

import axios from "axios";

import Loader from "components/common/Loader";

import OwnerDirectoryTable from "./OwnerDirectoryTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { ArrowForward } from "@mui/icons-material";

import { Link } from "react-router-dom";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { getHostName } from "helpers/utils";

const OwnersDirectory = () => {
  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  let hostname = getHostName();

  const [searchQuery, setSearchQuery] = useState("");

  const [propertiesData, setPropertiesData] = useState([]);
  const [propertiesObj, setPropertiesObj] = useState({
    matchingProperties: [],
    matchPropertyName: "",
    LoadMore: false,
  });

  const [loader, setLoader] = useState(false);

  const searchDataInDB = () => {
    setLoader(true);

    let c_id = userData.compid;
    console.log(c_id);
    // return;
    if (c_id) {
      axios
        .post(
          `${hostname}/api/property/ReadResidentDirectory`,
          {
            c_id,
            matchingWord: searchQuery,
          },
          {
            headers: {
              Authorization: `Bearer ${jwtToken}`,
            },
          }
        )
        .then((res) => {
          setLoader(false);
          console.log(res.data);
          let arr = [...res.data.Data];

          arr = arr?.map((user) => {
            let obj = { ...user };

            let role = { title: "Invited Owner", type: "bg-danger" };

            if (user.group === "Primary Owner")
              role = { title: "Primary Owner", type: "bg-primary" };
            if (user.group === "Supplementary Owner")
              role = { title: "Supplementary Owner", type: "bg-info" };
            if (user.group === "Primary Tenant")
              role = { title: "Primary Tenant", type: "bg-success" };
            if (user.group === "Supplementary Tenant")
              role = { title: "Supplementary Tenant", type: "bg-custom" };

            obj.role = role;

            let status = {};

            if (user.status === "Invited")
              status = { type: "info", title: "Invited", icon: "redo" };

            if (user.status === "Not Invited")
              status = { type: "dark", title: "Not Invited", icon: "redo" };

            if (user.status === "Active")
              status = { type: "success", title: "Active", icon: "check" };

            if (user.status === "Revoked")
              status = { type: "warning", title: "Revoked", icon: "ban" };

            if (user.status === "Ignored")
              status = { type: "secondary", title: "Ignored", icon: "redo" };
            obj.status = status;
            return obj;
          });

          console.log(arr);

          setSearchQuery("");
          setPropertiesData(arr);
          setPropertiesObj({
            matchingProperties: res.data.matchingProperties || [],
            matchPropertyName: res.data.matchPropertyName || "",
            LoadMore: res.data.LoadMore || false,
          });
          setLoader(false);
        })
        .catch((err) => {
          console.log(err);
          setLoader(false);
        });
    }
  };

  return (
    <>
      <Row className="g-3 mb-3">
        <Col>
          <Card>
            <Card.Body className="">
              <Link className="text-secondary" to={"/"}>
                Home
              </Link>
              {" / "}
              <Link to={"#!"}>Resident Directory</Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Card>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Flex justifyContent={"between"} alignItems={"start"} className="">
            <h4>Resident Directory</h4>
          </Flex>
          <br />
          {/* <br /> */}

          <Row className=" d-flex justify-content-center">
            <Col className="" md={8}>
              <Form.Group className="mb-3">
                <div className="input-group mb-3">
                  <span className="input-group-text bg-white" id="basic-addon2">
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                  <input
                    type="text"
                    name="searchv"
                    className="form-control form-control-lg"
                    placeholder={`Search in directory...`}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    value={searchQuery}
                  />
                  <Button
                    className="d-flex align-items-center justify-content-between"
                    onClick={() => searchDataInDB()}
                    disabled={!searchQuery}
                  >
                    Search <ArrowForward />{" "}
                  </Button>
                </div>
              </Form.Group>
            </Col>
          </Row>
          {/* <br /> */}
          <br />

          {loader && <Loader />}
          {!loader && <OwnerDirectoryTable properties={propertiesData} />}
          <br />
        </Card.Body>
      </Card>
    </>
  );
};

export default OwnersDirectory;
