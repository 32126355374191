import React from "react";
import Flex from "components/common/Flex";
import { Card, Row, Col, Image } from "react-bootstrap";
import IconButton from "components/common/IconButton";

import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Download } from "@mui/icons-material";
// import InboxModal from "./InboxModal";
import axios from "axios";

import pdfImgIcon from "assets/img/image-icons/pdf-img.png";
import textImgIcon from "assets/img/image-icons/text-img.png";
import docImgIcon from "assets/img/image-icons/doc-img.png";
import zipImgIcon from "assets/img/image-icons/zip-img.png";

import jwtDecode from "jwt-decode";
import Cookies from "universal-cookie";
import { formatLogInfo, getHostName } from "helpers/utils";

import {
  handleCreatePurePdf,
  getHtmlWithImageActualData,
} from "helpers/utils-pdf";
import { useNavigate, useParams } from "react-router-dom";
import { convert } from "html-to-text";
import { toast } from "react-toastify";

const ItemButton = ({ tootltip, icon, className, onClick }) => {
  return (
    <OverlayTrigger
      overlay={<Tooltip id="overlay-trigger-example">{tootltip}</Tooltip>}
    >
      <div className="d-inline-block">
        <IconButton
          variant="falcon-default"
          size="sm"
          icon={icon}
          className={className}
          onClick={onClick}
        />
      </div>
    </OverlayTrigger>
  );
};

const EmailDetail = () => {
  const [mailDetailsObj, setMailDetailsObj] = React.useState({});
  //main modal
  const [show, setShow] = React.useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //main action
  const [loader, setLoader] = React.useState(false);
  const [payload, setPayload] = React.useState({});

  let hostname = getHostName();

  const cookies = new Cookies();
  const jwtToken = cookies.get("_expire_t");
  const userData = jwtToken ? jwtDecode(jwtToken) : "";

  const { mail_id } = useParams();
  const navigate = useNavigate();

  // React.useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // }, [emailPayload]);

  React.useEffect(() => {
    loadMailDetails();
  }, []);

  const loadMailDetails = () => {
    setLoader(true);
    axios
      .post(
        `${hostname}/api/property/ReadMailDetail`,
        {
          inbox_id: mail_id,
          // user_id : userData.data
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        let mailObj = res.data.Data[0];

        if (typeof mailObj !== "object") return;

        // {
        //     "attachments": [],
        //     "mail_content": "<p>this is a temporary mail</p>",
        //     "mail_subject": "no Subject",
        //     "updatedAt": "2024-09-06T10:19:29.212Z",
        //     "sender_first_name": "Mujahid",
        //     "sender_last_name": "Khan",
        //     "sender_email": "mujahid.ad@mailinator.com",
        //     "inbox_mail_type": "mailout",
        //     "letter_content": "<p>this is for temorary letter</p>",
        //     "letterhead": "",
        //     "isRead": true,
        //     "_id": "66dad7217523732e8bd39bee"
        // }

        let oldObj = {
          id: "mail-1",
          mail_id: mailObj._id,
          user: "no-reply ",
          img: "",
          title: mailObj.mail_subject,
          descriptionFull: convert(mailObj.mail_content, { wordwrap: 0 }),
          descriptionFullHTML: mailObj.mail_content,
          mail_content: mailObj.mail_content,
          letter_content: mailObj.letter_content,
          letterhead: mailObj.letterhead,
          attachments: mailObj.attachments,
          time: formatLogInfo(mailObj.updatedAt, true),
          timeFull: formatLogInfo(mailObj.createdAt),
          sender_name:
            mailObj.sender_first_name + " " + mailObj.sender_last_name,
          sender_email: mailObj.sender_email,
          star: false,
          snooze: false,
          read: mailObj.isRead,
          deleted: mailObj.isDeleted,
          archived: mailObj.isArchive,
        };

        let mail_content_pure = mailObj.mail_content?.replace(
          /(\r\n|\n|\r)/gm,
          ""
        );

        mail_content_pure = removeInlineStyles(mail_content_pure);

        oldObj.description =
          oldObj.descriptionFull?.length > 95
            ? oldObj.descriptionFull?.slice(0, 95) + "..."
            : oldObj.descriptionFull;

        oldObj.descriptionFullHTML = mail_content_pure;

        let arr = [];
        if (oldObj.letter_content?.length > 0) {
          let attObj = {
            name: "Letter.pdf",
            data: oldObj.letter_content,
            size: 0,
            encoding: "custom-base64",
            mimetype: "application/pdf",
            _id: "letter_id_123321",
          };
          arr = Array.from(oldObj.attachments);
          arr.unshift(attObj);
          oldObj.attachments = arr;
        }
        console.log(oldObj);
        setMailDetailsObj(oldObj);
        setLoader(false);
      })
      .catch((err) => {
        setLoader(false);

        console.log(err);
      });
  };

  const callGivenOption = (optionName = "", obj = {}) => {
    let updatedObj = {
      inbox_id: obj.mail_id,
      isArchive: obj.archived,
      isDeleted: obj.deleted,
      isRead: obj.read,
    };

    if (optionName === "delete") updatedObj.isDeleted = true;

    if (optionName === "read") updatedObj.isRead = true;

    if (optionName === "unread") updatedObj.isRead = false;

    if (optionName === "archive") updatedObj.isArchive = true;

    if (optionName === "unarchive") updatedObj.isArchive = false;

    console.log(updatedObj);
    // return;

    axios
      .post(
        `${hostname}/api/property/UpdateInbox`,
        {
          ...updatedObj,
        },
        {
          headers: {
            Authorization: `Bearer ${jwtToken}`,
          },
        }
      )
      .then((res) => {
        console.log(res.data);

        if (optionName === "delete") {
          toast.warning("Mail Deleted");
          navigate(-1);
        }

        if (optionName === "read") toast.success("Status Updated");

        if (optionName === "unread") toast.success("Status Updated");

        if (optionName === "archive") toast.success("Mail Archived");

        if (optionName === "unarchive") toast.success("Removed from Archived");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //preview file
  const downloadAttachment = async (obj = {}, isLetter = false) => {
    console.log(isLetter);
    console.log(obj);
    // return;

    if (!isLetter) {
      // no conversion needed
      // let file = `data:${obj.mimetype};base64,${obj.data}`;
      let file = obj.data;
      console.log(file);
      // return;
      // const file_url = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = file;
      link.download = obj.name; // specify the filename
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      //convert letter

      console.log(obj);

      let htmlContentFinal = await getHtmlWithImageActualData(obj.data);
      // return;

      console.log(htmlContentFinal);

      if (mailDetailsObj.letterhead !== "") {
        handleCreatePurePdf(
          htmlContentFinal,
          {
            title: "letter content",
            author: "condozak",
          },
          true,
          mailDetailsObj.letterhead
        );
      } else {
        handleCreatePurePdf(htmlContentFinal, {
          title: "letter content",
          author: "condozak",
        });
      }

      // await printPages(
      //   obj.data,
      //   mailDetailsObj.letterhead.length > 0,
      //   mailDetailsObj.letterhead,
      //   true
      // );
    }
  };

  const previewAndPrint = async (htmlContent = "") => {
    console.log(htmlContent);
    
    let htmlContentFinal = await getHtmlWithImageActualData(htmlContent);

    handleCreatePurePdf(htmlContentFinal, {
      title: "mail content",
      author: "condozak",
    });
  };

  // Function to remove specific inline styles
  function removeInlineStyles(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    // Remove font-family from all elements that have it
    doc.querySelectorAll("[style]")?.forEach((element) => {
      const style = element.style;

      // Remove 'font-family' from inline styles
      style.removeProperty("font-family");

      // Remove 'text-decoration' from inline styles if present
      if (style.textDecoration) {
        style.removeProperty("text-decoration");
      }
    });

    return doc.body.innerHTML; // Return the modified HTML as a string
  }

  return (
    <>
      <Card className="mb-3 sticky-sidebar">
        <Card.Body className="d-flex justify-content-between">
          <ItemButton
            tootltip="Back to inbox"
            icon="arrow-left"
            onClick={() => navigate(-1)}
          />

          <div className="">
            {/* {!emailPayload.archived && ( //revese
              <ItemButton
                tootltip="Archive"
                icon="archive"
                className="ms-1 ms-sm-2 text-success"
                onClick={() => callGivenOption("archive", true, emailPayload)}
              />
            )}
            {emailPayload.archived && ( //revrse
              <ItemButton
                tootltip="Remove from Archive"
                icon="archive"
                className="ms-1 ms-sm-2 text-danger"
                onClick={() => callGivenOption("archive", true, emailPayload)}
              />
            )} */}
            <ItemButton
              tootltip="Delete"
              icon="trash-alt"
              className="ms-1 ms-sm-2 text-danger"
              onClick={() => callGivenOption("delete", mailDetailsObj)}
            />
            <ItemButton
              tootltip="Mark as unread"
              icon="envelope"
              className="ms-1 ms-sm-2 text-secondary"
              onClick={() => callGivenOption("unread", mailDetailsObj)}
            />
            <ItemButton
              tootltip="Print"
              icon="print"
              className="ms-1 ms-sm-2 text-primary"
              // onClick={() => printPages(mailDetailsObj.mail_content, false)}
              onClick={() =>
                previewAndPrint(mailDetailsObj.descriptionFullHTML)
              }
            />
          </div>
          {/* <Flex> */}
          {/* <div className="d-none d-md-block">
              <small> 2 of 354</small>
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="chevron-left"
                className="ms-2"
              />
              <IconButton
                variant="falcon-default"
                size="sm"
                icon="chevron-right"
                className="ms-2"
              />
            </div> */}
          {/* <Dropdown className="font-sans-serif" align="end">
              <Dropdown.Toggle
                variant="falcon-default"
                size="sm"
                className="text-600 dropdown-caret-none ms-2"
              >
                <FontAwesomeIcon icon="cog" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="py-2">
                <Dropdown.Item href="#/action-1">Configure inbox</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#/action-2">Settings</Dropdown.Item>
                <Dropdown.Item href="#/action-3">Themes</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#/action-4">Send feedback</Dropdown.Item>
                <Dropdown.Item href="#/action-4">Help</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown> */}
          {/* </Flex> */}
        </Card.Body>
      </Card>

      <Card className="">
        <Card.Header>
          <Row>
            <Col md as={Flex}>
              {/* <Avatar src={emailDetails.sender.avatarSrc} size="2xl" /> */}
              <div className="flex-1 ms-2">
                <h5 className="mb-0">{mailDetailsObj.title}</h5>
                <a
                  className="text-800 fs--1"
                  href={`mailto:${mailDetailsObj.sender_email}`}
                >
                  <span className="fw-semi-bold">
                    {mailDetailsObj.sender_name}
                    {/* Name Here */}
                  </span>{" "}
                  <span className="text-500">
                    &lt;{mailDetailsObj.sender_email}&gt;
                    {/* &lt;info@domain.co&gt; */}
                  </span>
                </a>
              </div>
            </Col>
            <Col
              md="auto"
              as={Flex}
              alignItems="center"
              className="ps-6 ps-md-3 container"
            >
              <small>{mailDetailsObj.timeFull}</small>
              {/* <FontAwesomeIcon
                onClick={() => setMarked(!marked)}
                icon={marked ? "star" : ["far", "star"]}
                className={classNames(
                  "ms-2 fs--1",
                  { "text-warning": marked, "text-300": !marked },
                  "cursor-pointer"
                )}
              /> */}
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="" style={{ minHeight: "90vh" }}>
          <Row className="justify-content-center mb-3">
            {/* <Col lg={8} xxl={6}> */}
            <Col>
              <Card className=" my-3 mx-3 overflow-scroll scrollbar">
                {/* <img src={womensDay} alt="" className="card-img-top" /> */}
                <Card.Body
                  style={{ minHeight: "90vh", whiteSpace: "pre-line" }}
                  dangerouslySetInnerHTML={{
                    // __html: emailPayload.descriptionFullHTML?.trim(),
                    __html: mailDetailsObj.descriptionFullHTML,
                  }}
                >
                  {/* {emailPayload.descriptionFull} */}
                </Card.Body>
              </Card>
            </Col>
          </Row>
          {/* <Row className="g-3 d-flex justify-content-center"> */}
          <Row className="mx-1">
            {mailDetailsObj?.attachments?.map((v) => (
              // {[1, 2, 3, 4, 5]?.map((v) => (
              <Col key={v._id} md={4}>
                <Card
                  className="mb-4"
                  style={
                    {
                      // height: "300px",
                      // width: "31.5%",
                      // backgroundColor: "red",
                      // border: "1px solid",
                    }
                  }
                >
                  {/* <img src={womensDay} alt="" className="card-img-top" /> */}
                  <Card.Body
                    className="p-0 cursor-pointer"
                    style={{ height: "200px", overflow: "hidden" }}
                  >
                    <div
                      style={{ height: "85%" }}
                      className=" border-bottom position-relative"
                    >
                      {(v.mimetype === "image/png" ||
                        v.mimetype === "image/jpeg" ||
                        v.mimetype === "image/jpg") && (
                        <div
                          className="d-flex justify-content-center align-items-center p-3"
                          style={{ height: "100%" }}
                        >
                          <Image src={v.data} style={{ width: "110px" }} />
                        </div>
                      )}
                      {v.mimetype === "application/x-zip-compressed" && (
                        <div
                          className="d-flex justify-content-center align-items-center p-3"
                          style={{ height: "100%" }}
                        >
                          <Image src={zipImgIcon} style={{ width: "70px" }} />
                        </div>
                      )}
                      {v.mimetype === "text/plain" && (
                        <div
                          className="d-flex justify-content-center align-items-center p-3"
                          style={{ height: "100%" }}
                        >
                          <Image src={textImgIcon} style={{ width: "60px" }} />
                        </div>
                      )}
                      {v.mimetype === "application/pdf" && (
                        <div
                          className="d-flex justify-content-center align-items-center p-3"
                          style={{ height: "100%" }}
                        >
                          <Image src={pdfImgIcon} style={{ width: "100px" }} />
                        </div>
                      )}
                      {v.mimetype === "application/octect-stream" && (
                        <div
                          className="d-flex justify-content-center align-items-center p-3"
                          style={{ height: "100%" }}
                        >
                          <Image src={textImgIcon} style={{ width: "80px" }} />
                        </div>
                      )}
                      {v.mimetype ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                        <div
                          className="d-flex justify-content-center align-items-center p-3"
                          style={{ height: "100%" }}
                        >
                          <Image src={docImgIcon} style={{ width: "80px" }} />
                        </div>
                      )}
                      <div
                        style={{
                          height: "50px",
                          width: "40px",
                          // backgroundColor: "silver",
                          position: "absolute",
                          right: 0,
                          top: 10,
                          zIndex: "9",
                        }}
                      >
                        <span
                          className="cursor-pointer text-secondary"
                          onClick={() =>
                            downloadAttachment(
                              v,
                              v.encoding === "custom-base64" ? true : false
                            )
                          }
                        >
                          <Download fontSize="large" className="text-success" />
                        </span>
                        {/* <span className="ms-2"></span> */}
                        {/* <span
                          className="cursor-pointer text-success"
                          onClick={() => previewAttachment(v)}
                        >
                          <Fullscreen fontSize="large" />
                        </span> */}
                      </div>
                    </div>

                    <div className="d-flex pt-1 px-3 align-items-center">
                      {v.name}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Card.Body>
      </Card>
      {/* <InboxModal show={show} handleClose={handleClose} payload={payload} /> */}

      <br />
    </>
  );
};

export default EmailDetail;
